import React from "react";
import { TextField, Button, Container } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createDeliveryBoy } from "../../store/actions/authActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import "./style.scss";
import avatar from "../../assets/images/avatar.png";

// const useStyles = makeStyles({
//     root: {
//         minWidth: 275,
//     },
//     bullet: {
//         display: 'inline-block',
//         margin: '0 2px',
//         transform: 'scale(0.8)',
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },
// });

class newUser extends React.Component {
  // classes = useStyles();
  // bull = <span className={this.classes.bullet}>•</span>;

  inputStyle = { background: "#fff" };

  buttonStyles = {
    height: "100%",
    background: "#0f9d58",
    borderRadius: "0px",
    color: "#fff",
    padding: "5px",
  };
  state = {
    name: "",
    userName: "",
    password: "",
    confirmPassword: "",
    countryCode: "",
    mobileNumber: "",
    flag: false,
    message: "",
    passwordFlag: false,
    passwordEmpty: false,
  };

  render() {
    return (
      <Container>
        <div className="delivery-boi__header">
          <div></div>
          <h1 style={{ color: "#63b9d9", fontWeight: "500" }}>Add New User</h1>
          <div></div>
        </div>

        <Card
          style={{
            width: "100%",
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            marginTop: "10px",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.05)",
              border: "1px solid #999",

              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  className="delivery-boi__info"
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="Name"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      style={{ width: "100%" }}
                    />
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      onChange={(e) => {
                        this.setState({ userName: e.target.value });
                      }}
                      style={{ width: "100%" }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      type="password"
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                      style={{ width: "100%" }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Confirm Password"
                      type="password"
                      onChange={(e) => {
                        this.setState({ confirmPassword: e.target.value });
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="Country Code"
                      onChange={(e) => {
                        this.setState({ countryCode: e.target.value });
                      }}
                      style={{
                        width: "20%",
                        marginRight: "5px",
                      }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Mobile Number"
                      onChange={(e) => {
                        this.setState({ mobileNumber: e.target.value });
                      }}
                      style={{ width: "80%", marginLeft: "5px" }}
                    />
                  </div>
                  {this.state.flag && (
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      {this.state.message}
                    </p>
                  )}
                  {this.state.passwordFlag && (
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Password doesn't match.
                    </p>
                  )}
                  {this.state.passwordEmpty && (
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Password field is empty.
                    </p>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => this.props.history.goBack()}
                    style={{ marginTop: "20px" }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: "#f37f0a",
                      color: "white",
                      marginTop: "20px",
                    }}
                    onClick={(e) => {
                      this.onSave();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    );
  }
  onSave = async () => {
    const {
      name,
      userName,
      password,
      confirmPassword,
      countryCode,
      mobileNumber,
    } = this.state;
    if (password.trim() !== "") {
      if (password.trim() !== "" && password === confirmPassword) {
        let res = await this.props.save({
          name,
          userName,
          password,
          countryCode,
          mobileNumber,
        });
        if (res.data) {
          this.setState({
            name: "",
            userName: "",
            password: "",
            countryCode: "",
            mobileNumber: "",
          });
          this.props.history.goBack();
        } else {
          console.log("response obj", res);
          this.setState({ flag: true, message: res.response.data.message });
          setTimeout(async () => {
            this.setState({ flag: false });
          }, 3000);
        }
      } else {
        this.setState({ passwordFlag: true });
        setTimeout(async () => {
          this.setState({ passwordFlag: false });
        }, 3000);
      }
    } else {
      this.setState({ passwordEmpty: true });
      setTimeout(async () => {
        this.setState({ passwordEmpty: false });
      }, 3000);
    }
  };
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    save: (data) => dispatch(createDeliveryBoy(data, router)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(newUser)
);
