import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Button,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { redirect } from "../../constants/utils";
import logo from "../../assets/images/siteLogo.jpg";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { getCompleted, getReport } from "../../store/actions/authActions";
import { connect } from "react-redux";

class FulfilledReports extends Component {
  state = {
    timeSlots: [],
    limit: 100,
    start: new Date(),
    end: new Date(),
    type: "_id",
    sort: "DESC",
    orders: [],
    pageNo: 1,
    all: false,
  };
  componentDidMount() {
    var now = moment().clone();

    var start = now.startOf("day").format("YYYY-MM-DD HH:mm:ss");
    var end = now.endOf("day").format("YYYY-MM-DD HH:mm:ss");
    let date = now.format("YYYY-MM-DD");

    let newStart = date + "T00:00";
    let newEnd = date + "T23:59";
    this.setState({
      start: newStart,
      end: newEnd,
    });
    this.props.getCompleted({
      start,
      end,
      type: "_id",
      sort: "DESC",
    });
  }
  render() {
    return (
      <div>
        <div
          style={{
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "20px",
            boxShadow: "0 0 3px 0px rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#f9f9f9",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
            >
              <img src={logo} height="100px" width="150px" alt="" />
            </div>
            <div
              style={{
                marginLeft: "50px",
                display: "flex",
                flexDirection: "column",
                width: "550px",
              }}
            >
              <h1>Fulfilled Orders</h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "40%",
                  }}
                >
                  From:
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                  }}
                >
                  To:
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "40%",
                  }}
                >
                  <TextField
                    id="datetime-local"
                    // label="Next appointment"
                    type="datetime-local"
                    defaultValue={this.state.start}
                    value={this.state.start}
                    // className={classes.textField}
                    onChange={(e) => {
                      console.log("date", e.target.value);
                      this.setState({ start: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "40%",
                    marginLeft: "10px",
                  }}
                >
                  <TextField
                    id="datetime-local"
                    // label="Next appointment"
                    type="datetime-local"
                    onChange={(e) => this.setState({ end: e.target.value })}
                    defaultValue={this.state.end}
                    value={this.state.end}
                    // className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div
                  onClick={(e) =>
                    this.props.getCompleted({
                      start: this.state.start,
                      end: this.state.end,
                      type: this.state.type,
                      sort: this.state.sort,
                    })
                  }
                  style={{
                    display: "flex",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "2px",
                    marginLeft: "10px",
                    alignItems: "center",
                    backgroundColor: "#216dbf",
                    cursor: "pointer",
                  }}
                >
                  <ReplayIcon />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                onClick={(e) => this.genrateReport("csv")}
                style={{
                  display: "flex",
                  boxShadow: "0 0 3px 0px rgba(0, 0, 0, 0.15)",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              >
                Export as .csv
              </div>
              <div
                onClick={(e) => this.genrateReport("xlsx")}
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  boxShadow: "0 0 3px 0px rgba(0, 0, 0, 0.15)",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              >
                Export as .xlsx
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          show
          <Select
            style={{ border: "1px solid", borderRadius: "4px" }}
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-outlined"
            value={this.state.limit}
            // onChange={handleChange}
            onChange={(e) => {
              this.setState({ limit: e.target.value });
              if (e.target.value === "") {
                this.setState({ limit: "", all: true });
              } else {
                this.setState({ limit: e.target.value, all: false });
              }
            }}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={this.state.orders.length}>all</MenuItem>
          </Select>
          elements
        </div>
        {/*table*/}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("_id", e)}
                  >
                    ORDER NO
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("vendor", e)}
                  >
                    VENDOR CODE
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("vendorName", e)}
                  >
                    VENDOR NAME
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("receipient", e)}
                  >
                    RECIPIENT NAME
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("deliveryMode", e)}
                  >
                    DELIVERY MODE
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("deliveryDate", e)}
                  >
                    DELIVERY DATE
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("deliveredDate", e)}
                  >
                    DELIVERED DATE
                  </Link>
                </TableCell>
                <TableCell>DELIVERED TIME</TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("deliverySlot", e)}
                  >
                    DELIVERY SLOT
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("city", e)}
                  >
                    CITY
                  </Link>
                </TableCell>
                {/* <TableCell>PIN CODE</TableCell> */}
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("grand", e)}
                  >
                    GRAND_TOTAL
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("receiverName", e)}
                  >
                    RECEIVER_NAME
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("status", e)}
                  >
                    STATUS_ID
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("allocatedDate", e)}
                  >
                    ALLOCATED DATE
                  </Link>
                </TableCell>
                <TableCell>ALLOCATED TIME</TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("acceptedDate", e)}
                  >
                    ACCEPTED DATE
                  </Link>
                </TableCell>
                <TableCell>ACCEPTED TIME</TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.handleSort("vendor", e)}
                  >
                    PRIMARY VENDOR NAME
                  </Link>
                </TableCell>
                {/* <TableCell>SOURCE</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.orders.map(
                (order, index) =>
                  (((this.state.pageNo - 1) * this.state.limit <= index &&
                    this.state.pageNo * this.state.limit > index) ||
                    this.state.all) && (
                    <TableRow>
                      <TableCell>{order.subOrderId}</TableCell>
                      <TableCell>{order.partyId}</TableCell>
                      <TableCell>{order.vendorName}</TableCell>
                      <TableCell>{order.recipientName}</TableCell>
                      <TableCell>{order.deliveryMode}</TableCell>
                      <TableCell>
                        {moment(order.shippingDate).utc(true).format("Y-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {order.deliveryDate
                          ? moment(order.deliveryDate)
                              .utc(true)
                              .format("Y-MM-DD")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {order.deliveryDate
                          ? moment(order?.deliveryDate)
                              .utc(true)
                              .format("HH:mm:ss")
                          : ""}
                      </TableCell>
                      <TableCell>{order.shippingTime}</TableCell>
                      <TableCell>{order.city}</TableCell>
                      {/* <TableCell>1</TableCell> */}
                      <TableCell>{order.amount}</TableCell>
                      <TableCell>{order.receivedBy}</TableCell>
                      <TableCell>{order.deliveryStatus}</TableCell>
                      <TableCell>
                        {moment(order?.allocatedDate)
                          .utc(true)
                          .format("Y-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {order.allocatedDate
                          ? moment(order?.allocatedDate)
                              .utc(true)
                              .format("HH:mm:ss")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {moment(order?.acceptedDate)
                          .utc(true)
                          .format("Y-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {order?.acceptedDate
                          ? moment(order?.acceptedDate)
                              .utc(true)
                              .format("HH:mm:ss")
                          : ""}
                      </TableCell>
                      <TableCell>{order.vendorName}</TableCell>
                      {/* <TableCell>test</TableCell> */}
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => {
              if (this.state.pageNo > 1) {
                this.setState({ pageNo: this.state.pageNo - 1 });
              }
            }}
          >
            Prev
          </Button>
          <Button
            onClick={() => {
              let pages = Math.ceil(
                this.state.orders.length / this.state.limit
              );
              if (this.state.pageNo < pages) {
                this.setState({ pageNo: this.state.pageNo + 1 });
              }
            }}
            style={{ marginRight: "10px" }}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
  genrateReport = async (type) => {
    let newNow = moment();
    var start = newNow.startOf("day").toDate();
    const date = new Date();
    const offset = date.getTimezoneOffset();
    let parentArray = [];
    let allocatedFuture = await this.props.getReport({
      start: this.state.start,
      end: this.state.end,
    });
    let firstRow = [
      "orderId",
      "Vendor Id",
      "vendor Name",
      "Recipient Name",
      "Shipping Mode",
      "Delivery Date",
      "Delivered Date",
      "Delivered Time",
      "Delivery Slot",
      "Recipient City",
      "Grand Total",
      "Receiver name",
      "Shipment Status",
      "Allocated Date",
      "Allocated Time",
      "Accepted Date",
      "Accepted Time",
    ];
    parentArray.push(firstRow);
    let data = this.getSubOrders(this.props.completedOrders);
    parentArray = parentArray.concat(data);
    let dateString = date.toLocaleDateString();
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "SheetJS Tutorial",
      Subject: "Test",
      Author: "Red Stapler",
      CreatedDate: new Date(2017, 12, 19),
    };
    wb.SheetNames.push("Sheet1");
    var ws = XLSX.utils.aoa_to_sheet(parentArray, { header: 1 });
    wb.Sheets["Sheet1"] = ws;

    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    if (type === "xlsx") {
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "cda exports" + dateString + ".xlsx"
      );
    } else {
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "cda exports" + dateString + ".csv"
      );
    }
  };
  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  handleSort(type, e) {
    e.preventDefault();
    let sort = "";
    if (this.state.sort == "DESC") {
      sort = "ASC";
      this.setState({ sort: "ASC", type });
    } else {
      sort = "DESC";
      this.setState({ sort: "DESC", type });
    }
    this.props.getCompleted({
      start: this.state.start,
      end: this.state.end,
      type: type,
      sort: sort,
    });
  }
  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.completedOrders.length !== this.props.completedOrders.length
    ) {
      let orders = JSON.parse(JSON.stringify(this.props.completedOrders));
      let data = this.renderOrders(this.props.completedOrders);
      this.setState({ orders: data });
    }
  };

  renderOrders = (orders) => {
    // let orders = this.state.orders;
    let parentArray = [];
    for (let i = 0; i < orders.length; i++) {
      let obj = {};
      obj.subOrderId = orders[i][0].subOrderId;
      obj.partyId = orders[i][0].partyId;
      let party = orders[i][0].parties.find(
        (f) => f._id === orders[i][0].partyId
      );
      if (party) {
        obj.vendorName = party.name;
      } else {
        obj.name = "";
      }
      obj.recipientName = orders[i][0].address.name;
      obj.deliveryMode = orders[i][0].shippingType;
      obj.shippingDate = moment(orders[i][0].shippingDate)
        .utc(true)
        .format("Y-MM-DD");
      obj.deliveryDate = orders[i][0].deliveryDate;
      obj.shippingTime = orders[i][0].shippingTime;
      obj.city = orders[i][0].address.recipientCity;

      let amount = 0;
      let shipping = 0;
      for (let j = 0; j < orders[i].length; j++) {
        if (shipping < orders[i][j].shippingPrice) {
          shipping = orders[i][j].shippingPrice;
        }
        amount = amount + orders[i][j].amount * orders[i][j].quantity;
        if (orders[i][j].addons.length > 0) {
          for (let k = 0; k < orders[i][j].addons.length; k++) {
            amount =
              amount +
              orders[i][j].addons[k].amount * orders[i][j].addons[k].quantity;
          }
        }
      }
      amount = amount + shipping;
      obj.amount = amount;
      obj.receivedBy = orders[i][0].receivedBy;
      obj.deliveryStatus = orders[i][0].deliveryStatus;
      obj.allocatedDate = orders[i][0].allocatedDate;
      obj.acceptedDate = orders[i][0].acceptedDate;
      parentArray.push(obj);
    }

    return parentArray;
  };

  getSubOrders = (orders) => {
    let pArray = [];
    for (let i = 0; i < orders.length; i++) {
      let childArray = [];
      childArray.push(orders[i][0].subOrderId);
      childArray.push(orders[i][0].partyId);
      let p = orders[i][0].parties.find((f) => f._id === orders[i][0].partyId);
      childArray.push(p.name);
      childArray.push(orders[i][0].address.name);
      childArray.push(orders[i][0].shippingType);
      childArray.push(
        moment(orders[i][0].shippingDate).utc(true).format("Y-MM-DD")
      );
      if (orders[i][0].deliveryDate) {
        childArray.push(
          moment(orders[i][0].shippingDate).utc(true).format("Y-MM-DD")
        );
        childArray.push(
          moment(orders[i][0].shippingDate).utc(true).format("HH:mm:ss")
        );
      } else {
        childArray.push(
          moment(orders[i][0].lastAttempted).utc(true).format("Y-MM-DD")
        );
        childArray.push(
          moment(orders[i][0].lastAttempted).utc(true).format("HH:mm:ss")
        );
      }
      childArray.push(orders[i][0].shippingTime);
      childArray.push(orders[i][0].address.recipientCity);

      let amount = 0;
      let shipping = 0;
      for (let j = 0; j < orders[i].length; j++) {
        if (shipping < orders[i][j].shippingPrice) {
          shipping = orders[i][j].shippingPrice;
        }
        amount = amount + orders[i][j].amount * orders[i][j].quantity;

        if (orders[i][j].addons.length > 0) {
          for (let k = 0; k < orders[i][j].addons.length; k++) {
            amount =
              amount +
              orders[i][j].addons[k].amount * orders[i][j].addons[k].quantity;
          }
        }
      }
      amount = amount + shipping;
      childArray.push(amount);
      childArray.push(orders[i][0].receivedBy);
      childArray.push(orders[i][0].deliveryStatus);
      childArray.push(
        moment(orders[i][0].allocatedDate).utc(true).format("Y-MM-DD")
      );
      childArray.push(
        moment(orders[i][0].allocatedDate).utc(true).format("HH:mm:ss")
      );
      childArray.push(
        moment(orders[i][0].acceptedDate).utc(true).format("Y-MM-DD")
      );
      childArray.push(
        moment(orders[i][0].acceptedDate).utc(true).format("HH:mm:ss")
      );
      pArray.push(childArray);
    }

    return pArray;
  };
}
const mapStateToProps = (state) => {
  return {
    completedOrders: state.auth.completedOrders,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    getCompleted: (data) => dispatch(getCompleted(data, router)),
    getReport: (data) => dispatch(getReport(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FulfilledReports)
);
