import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { redirect } from "../../constants/utils";
import {
  getPending3,
  updateDeliveryStatus,
} from "../../store/actions/authActions";
import { connect } from "react-redux";

class DeliveryBoy extends Component {
  state = {
    pending: 0,
    late: 0,
    out: 0,
    attempted: 0,
    total: 0,
    name: "",
  };
  componentDidMount = async () => {
    let userData = localStorage.getItem("userData");
    let userD = JSON.parse(userData);
    let name = userD.name;
    this.setState({ name: name });
    let newNow = moment();
    var start = newNow.startOf("day").toDate();
    var end = newNow.endOf("day").toDate();
    let pending = await this.props.getPending({ start, end });
    let out = await this.props.getPending({
      start,
      end,
      status: "OUT_FOR_DELIVERY",
    });
    let attempted = await this.props.getPending({
      start,
      end,
      status: "ATTEMPTED",
    });
    let newStart = newNow.startOf("month").toDate();
    let newEnd = newNow.endOf("month").toDate();
    let total = await this.props.getPending({
      start: newStart,
      end: newEnd,
      status: "DELIVERED",
    });

    this.setState({
      pending: pending.length,
      out: out.length,
      attempted: attempted.length,
      total: total.length,
    });
  };
  render() {
    return (
      <Container style={{ alignItems: "center", justifyContent: "center" }}>
        <div className="delivery-boi__header">
          <div></div>
          <h1 style={{ color: "#63b9d9", fontWeight: "500" }}>
            {this.state.name} Orders
          </h1>
          <div></div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px",
            flexDirection: "column",
          }}
        >
          <div
            onClick={(e) => {
              this.props.history.push({
                pathname: "/ShowPendingOrders",
                state: {
                  type: "pending",
                },
              });
            }}
            style={{
              backgroundColor: "#b1f1b4",
              width: "70%",
              height: "40px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              cursor: "pointer",
            }}
          >
            <h3>Pending Orders ({this.state.pending})</h3>
          </div>
          <div
            style={{
              backgroundColor: "#fbbece",
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              height: "40px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <h3>Late Orders (0)</h3>
          </div>
          <div
            onClick={(e) => {
              this.props.history.push({
                pathname: "/ShowPendingOrders",
                state: {
                  type: "out",
                },
              });
            }}
            style={{
              backgroundColor: "#d9666b",
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              height: "40px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <h3>Out For Delivery ({this.state.out})</h3>
          </div>
          <div
            style={{
              backgroundColor: "#f8e1b8",
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              height: "40px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              this.props.history.push({
                pathname: "/ShowPendingOrders",
                state: {
                  type: "attempted",
                },
              });
            }}
          >
            <h3>Attempted Orders ({this.state.attempted})</h3>
          </div>
          <div
            style={{
              backgroundColor: "skyblue",
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              height: "40px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              this.props.history.push({
                pathname: "/ShowPendingOrders",
                state: {
                  type: "total",
                },
              });
            }}
          >
            <h3>Total Month Orders ({this.state.total})</h3>
          </div>
        </div>
      </Container>
      // <h1>something</h1>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orders: state.auth.orders,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    getPending: (data) => dispatch(getPending3(data, router)),
    updateDeliveryStatus: (data) =>
      dispatch(updateDeliveryStatus(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryBoy)
);
