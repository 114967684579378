import React, {Component} from "react";
import {
    Container,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Button,
} from "@material-ui/core";
import moment from "moment";
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";
import {
    getOrders,
    getAllocated,
    setParty,
    getImage,
    getDelivered,
    getOrder,
} from "../../store/actions/authActions";
import img from "../../assets/images/excel.jpg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Row from "./row";
import pdfMake from "pdfmake";
import imag from "../../assets/images/siteLogo.jpg";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JsBarcode from "jsbarcode";

const {createCanvas, loadImage} = require("canvas");

const WhiteBackgroundCheckbox = withStyles((theme) => ({
    root: {
        color: "gray",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0,
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: "white",
            zIndex: -1,
        },
    },
    checked: {},
}))(Checkbox);
var watermark = {
    watermark: { text: 'COD', color: 'red', opacity: 0.3, bold: true, italics: false },
}
var Amount = [
    { text: "Amount: ", bold: true },
    { text: "100",
        bold:true,
        color:'#FF0000',
        fontSize:20},
]
var message = [
    {
        fillColor: "#f0f8ff",

        columns: [
            {text: ""},
            {
                margin: [20, 20],
                table: {
                    heights: [20, 150, 10, 20],
                    body: [
                        ["barney"],
                        [
                            "\nWishing you a speedy and full recovery <3 Hope you feel much better very soon. P.S it's super boring without you!",
                        ],
                        ["From"],
                        ["ABC"],
                    ],
                },
                layout: "noBorders",
            },
        ],
    },
];
var logoRow = [
    {
        columns: [
            {
                margin: [20, 20],
                image: "sampleImage.jpg",
                width: 100,
                height: 40,
            },
            {text: ""},
            {
                margin: [0, 20, 20, 20],
                image: "sampleImage.jpg",
                width: 100,
                height: 40,
            },
        ],
    },
];
var addressRow = [
    {
        columns: [
            {
                margin: [30, 20, 20, 20],
                text: [
                    {text: "Deliver To", bold: true},
                    {text: "\nTo: "},
                    {text: "Barny Shaw"},
                    {text: "\nApartment 102, 55 al ramith,"},
                    {text: "\nremraam Dubai uae"},
                    {text: "\nDubai"},
                    {text: "\n03075861606"},
                ],
            },
            {
                margin: [50, 20, 0, 20],
                text: [
                    {text: "\nOrder#: ", bold: true},
                    {text: "66287101"},
                    {text: "\nOrder Date: ", bold: true},
                    {text: "25-11-2020"},
                    {text: "\nDelivery Date: ", bold: true},
                    {text: "25-11-2020"},
                    {text: "\nShipping: ", bold: true},
                    {text: "Standard_Delivery"},
                    {text: "\nTime: ", bold: true},
                    {text: "15:00:00 - 19:00:00"},
                ],
            },
        ],
    },
];
var productRow = [
    {
        table: {
            widths: [120, 100, 150, "*", 50],
            body: [
                [
                    {text: "Product Image", bold: true},
                    {text: "Product", bold: true},
                    {text: "Product Description", bold: true},
                    {text: "Uploaded Image", bold: true},
                    {text: "Quantity", bold: true},
                ],
            ],
        },
        layout: {
            hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
            },
            vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0 : 1;
            },
            hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length ? "black" : "black";
            },
            vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length ? "black" : "black";
            },
            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // paddingLeft: function(i, node) { return 4; },
            // paddingRight: function(i, node) { return 4; },
            // paddingTop: function(i, node) { return 2; },
            // paddingBottom: function(i, node) { return 2; },
            // fillColor: function (rowIndex, node, columnIndex) { return null; }
        },
    },
];
var senderRow = [
    {
        margin: [30, 20],
        text: [{text: "Sender: "}, {text: "Nagla"}],
    },
];
var external = {
    hLineWidth: function (i, node) {
        //	return (i !== 1 || i===2   ) ? 0 : 1;
        return i === 1 || i === 2 ? 1 : 0;
    },
    vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0 : 1;
    },
    hLineColor: function (i, node) {
        return "no";
    },
    vLineColor: function (i, node) {
        return "black";
    },
    hLineStyle: function (i, node) {
        // if (i !== 1) {
        //     return null;
        // }
        // return {dash: {length: 5, space: 4}};
    },
    vLineStyle: function (i, node) {
        if (i === 0 || i === node.table.widths.length) {
            return null;
        }
        return {dash: {length: 4}};
    },
};
var internal = {
    hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 1 : 0;
    },
    vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0 : 1;
    },
    hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? "black" : "black";
    },
    vLineColor: function (i, node) {
        return i === 0 || i === node.table.widths.length ? "black" : "black";
    },
};
var cc = {
    pageMargins: [0, 0, 0, 0],
    content: [],
    styles: {
        header: {
            fillColor: "gray",
        },
    },
};
let contentObj = {
    table: {
        // heights: [250],
        widths:['*'],
        body: [],
    },
    layout: {
        hLineWidth: function (i, node) {
            //	return (i !== 1 || i===2   ) ? 0 : 1;
            return i === 1 || i === 2 ? 1 : 0;
        },
        vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 1;
        },
        hLineColor: function (i, node) {
            return "no";
        },
        vLineColor: function (i, node) {
            return "black";
        },
        hLineStyle: function (i, node) {
            if (i !== 1) {
                return null;
            }
            return {line: {length: 5, space: 4}};
        },
        vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
                return null;
            }
            return {dash: {length: 4}};
        },
        // paddingLeft: function(i, node) { return 4; },
        // paddingRight: function(i, node) { return 4; },
        // paddingTop: function(i, node) { return 2; },
        // paddingBottom: function(i, node) { return 2; },
        // fillColor: function (i, node) { return null; }
    },
};

class ListOrders extends Component {
    quantityObj(quantity) {
        let obj = {};
        obj.margin = [10, 40];
        obj.alignment = "center";
        obj.text = quantity;
        obj.fontSize = 20;
        obj.bold = true;
        obj.color='#FF0000'
        return obj;
    }

    async imageObj(url) {
        let obj = {};
        obj.width = 100;
        obj.height = 60;
        obj.margin = [10, 20];
        if (url) {
            let d = await this.convertUrl(url);
            obj.image = d;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    description(des) {
        let obj = {};
        obj.margin = [0, 20];
        obj.fontSize = 10;
        if (des) {
            obj.text = des;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    productName(name) {
        let obj = {};
        obj.margin = [0, 20];
        obj.fontSize = 10;
        if (name) {
            obj.text = name;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    async productImg(url) {
        let obj = {};
        obj.width = 120;
        obj.height = 100;
        // obj.margin = [10, 20];
        if (url) {
            let d = await this.convertUrl(url);
            obj.image = d;
            return obj;
        } else {
            obj.text = "";
            return obj;
        }
    }

    async makeRow(productUrl, name, desc, imageUrl, quantity) {
        let array = [];
        let productObj = await this.productImg(productUrl);
        let productName = this.productName(name);
        let des = this.description(desc);
        let imageObj = await this.imageObj(imageUrl);
        let qty = this.quantityObj(quantity);
        array.push(productObj);
        array.push(productName);
        array.push(des);
        array.push(imageObj);
        array.push(qty);
        return array;
    }

    printChallan = async (dataObj) => {
        let lRow = JSON.parse(JSON.stringify(logoRow));
        let addRow = JSON.parse(JSON.stringify(addressRow));
        let pRow = JSON.parse(JSON.stringify(productRow));
        let sendRow = JSON.parse(JSON.stringify(senderRow));

        let content = JSON.parse(JSON.stringify(contentObj));

        const canvas = createCanvas(500, 500);
        JsBarcode(canvas, dataObj.orderId);
        content.layout = external;
        lRow[0].columns[2].image = canvas.toDataURL();
        //populating siteLogo
        let data = await this.convert(imag);
        lRow[0].columns[0].image = data;

        //populating orderDetails
        addRow[0].columns[1].text[1].text = dataObj.orderId;
        addRow[0].columns[1].text[3].text = moment(dataObj.orderDate)
            .utc(true)
            .format("Y-MM-DD");
        addRow[0].columns[1].text[5].text = moment(dataObj.shippingDate)
            .utc(true)
            .format("Y-MM-DD");
        addRow[0].columns[1].text[7].text = dataObj.shippingType;
        addRow[0].columns[1].text[9].text = dataObj.shippingTime;
        //populating delivery detail
        addRow[0].columns[0].text[2].text = dataObj.deliveryinfo?.name;
        addRow[0].columns[0].text[3].text =
            "\n" + dataObj.deliveryinfo?.recipientAddress;
        addRow[0].columns[0].text[4].text = "\n" + dataObj.deliveryinfo?.landmark;
        addRow[0].columns[0].text[5].text =
            "\n" + dataObj.deliveryinfo?.recipientCity;
        addRow[0].columns[0].text[6].text = "\n" + dataObj.deliveryinfo?.phone;

        pRow[0].layout = internal;
        sendRow[0].text[1].text = dataObj.senderName;
        let mRow = {};
        //populating productInfo
        return new Promise(async (resolve, reject) => {
            for (let i = 0; i < dataObj.products.length; i++) {
                mRow = JSON.parse(JSON.stringify(message));
                let qty = dataObj.products[i].quantity;
                let pImg = dataObj.products[i].pImg;
                let pName = dataObj.products[i].name;
                let des = dataObj.products[i].description;
                let uImg = dataObj.products[i].image;
                if (dataObj.products[i].giftCard) {
                    mRow[0].columns[1].table.body[0][0] =
                        dataObj.products[i].giftCard_receiver;
                    mRow[0].columns[1].table.body[1][0] =
                        dataObj.products[i].giftCard_message;
                    mRow[0].columns[1].table.body[3][0] =
                        dataObj.products[i].giftCard_sender;
                    // content.table.body.push(mRow);
                }
                let arr = await this.makeRow(pImg, pName, des, uImg, qty);
                pRow[0].table.body.push(arr);
            }
            if (content.table.body.length < 1) {
                mRow[0].columns[1].table.body[0][0] = "";
                mRow[0].columns[1].table.body[1][0] = "";
                mRow[0].columns[1].table.body[3][0] = "";
                // content.table.body.push(mRow);
            }
            if(dataObj.amount+dataObj.shipping>dataObj.paidAmount){
                let AmountRow = JSON.parse(JSON.stringify(Amount));
                AmountRow[1].text = dataObj.amount+dataObj.shipping-dataObj.paidAmount;
                addRow[0].columns[1].text.unshift(AmountRow[1]);
                addRow[0].columns[1].text.unshift(AmountRow[0]);
            }
            content.table.body.push(lRow);
            content.table.body.push(addRow);
            content.table.body.push(pRow);
            // content.table.body.push(sendRow);
            resolve(content);
        });
    };
    convertUrl = async (url) => {
        let res = await this.props.getImage({url: url});
        let newD = res;
        return newD;
    };
    convert = (image) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = image;
            img.onload = function () {
                var canvas = document.createElement("canvas"),
                    context = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0, img.width, img.height);
                const data = canvas.toDataURL("image/png");
                resolve(data);
            };
        });
    };
    printBulk = async () => {
        let dd = JSON.parse(JSON.stringify(cc));
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        let ids = this.state.selectedIds;
        let dataArray = await this.getArrayCopy(this.props.orders, ids);
        new Promise(async (resolve, reject) => {

            for (let i = 0; i < dataArray.length; i++) {
                let contentObj = await this.printChallan(dataArray[i]);
                if (i === 0) {
                } else {
                    contentObj.pageBreak = "before";
                }
                if(dataArray[i].amount+dataArray[i].shipping>dataArray[i].paidAmount){
                    dd.watermark = watermark.watermark;
                }
                dd.content.push(contentObj);
            }
            pdfMake.createPdf(dd).open();
        });
    };
    getArrayCopy = (carts, ids) => {
        let array = [];

        let cartsArray = carts.filter(c => ids.some(id => id === c[0].subOrderId));
        if (cartsArray.length) {
            for (let k = 0; k < cartsArray.length; k++) {
                let obj = {};
                let dataChunck = cartsArray[k];

                obj.deliveryinfo = dataChunck[0]?.address;
                obj.orderId = dataChunck[0]?.subOrderId;
                obj.shippingDate = dataChunck[0]?.shippingDate;
                obj.shippingTime = dataChunck[0]?.shippingTime;
                obj.shippingType = dataChunck[0]?.shippingType;

                let products = [];
                let amount = 0;
                let paidAmount = 0;
                let shipping = 0;
                for (let i = 0; i < dataChunck.length; i++) {
                    let object = {};
                    object.quantity = dataChunck[i].quantity;
                    object.pImg = dataChunck[i].products[0].images?.[0];
                    object.name = dataChunck[i].products[0].title;
                    object.description = dataChunck[i].products[0].description;
                    object.image = dataChunck[i].image;
                    object.giftCard = dataChunck[i].giftCard;
                    object.giftCard_occasion = dataChunck[i].giftCard_occasion;
                    object.giftCard_message = dataChunck[i].giftCard_message;
                    object.giftCard_receiver = dataChunck[i].giftCard_receiver;
                    object.giftCard_sender = dataChunck[i].giftCard_sender;
                    paidAmount = paidAmount + dataChunck[i].paidAmount;
                    amount = amount + dataChunck[i].amount;
                    shipping =shipping + dataChunck[i].shippingPrice;

                    products.push(object);
                    if (dataChunck[i].addons.length > 0) {
                        for (let j = 0; j < dataChunck[i].addons.length; j++) {
                            let newObj = {};
                            newObj.quantity = dataChunck[i].addons[j].quantity;
                            newObj.pImg = dataChunck[i].addons[j].products[0].images?.[0];
                            newObj.name = dataChunck[i].addons[j].products[0].title;
                            newObj.description =
                                dataChunck[i].addons[j].products[0].description;
                            newObj.image = dataChunck[i].addons[j].image;
                            amount = amount + dataChunck[i].addons[j].amount;
                            paidAmount = paidAmount + dataChunck[i].addons[j].paidAmount;
                            products.push(newObj);
                        }
                    }
                    obj.products = products;
                    obj.senderName = dataChunck[0].order.senderName;
                    obj.orderDate = dataChunck[0].order.createdDate;

                }
                obj.amount = amount;
                obj.paidAmount = paidAmount;
                obj.shipping = shipping;
                array.push(obj);
            }
        }
        return array;

    }
    getArray = (orders, ids) => {
        // let orders = this.state.orders;
        let array = [];
        let lastIndex = orders.length - 1;
        let list2 = [];

        orders &&
        orders.map((orderObj, index) => {
            debugger;
            let filtered = orderObj
            if (filtered.length > 0) {
                let count = 1;
                //let amount = order?.amount;
                let carts = filtered;
                let list = [];
                while (carts.length > 0) {
                    count = count + 1;
                    let order = carts[0].deliveryAddress._id;
                    let dataChunck = carts;
                    dataChunck.forEach((f) =>
                        carts.splice(
                            carts.findIndex((e) => e._id === f._id),
                            1
                        )
                    );
                    let amount = 10 * dataChunck.length;
                    dataChunck.map((cart) => {
                        amount = amount + cart?.amount * cart?.quantity;
                        cart.addons &&
                        cart.addons.map((addon) => {
                            amount =
                                amount +
                                addon.amount * addon.quantity;
                        });
                    });
                    let obj = {};
                    obj.deliveryinfo = dataChunck[0]?.deliveryAddress;
                    obj.orderId = dataChunck[0]?.subOrderId;
                    obj.shippingDate = dataChunck[0]?.shippingDate;
                    obj.shippingTime = dataChunck[0]?.shippingTime;
                    obj.shippingType = dataChunck[0]?.shippingType;
                    let products = [];

                    for (let i = 0; i < dataChunck.length; i++) {
                        let object = {};
                        object.quantity = dataChunck[i].quantity;
                        object.pImg = dataChunck[i].products[0].images?.[0];
                        object.name = dataChunck[i].products[0].title;
                        object.description = dataChunck[i].products[0].description;
                        object.image = dataChunck[i].image;
                        object.giftCard = dataChunck[i].giftCard;
                        object.giftCard_occasion = dataChunck[i].giftCard_occasion;
                        object.giftCard_message = dataChunck[i].giftCard_message;
                        object.giftCard_receiver = dataChunck[i].giftCard_receiver;
                        object.giftCard_sender = dataChunck[i].giftCard_sender;
                        products.push(object);
                        if (dataChunck[i].addons.length > 0) {
                            for (let j = 0; j < dataChunck[i].addons.length; j++) {
                                let newObj = {};
                                newObj.quantity = dataChunck[i].addons[j].quantity;
                                newObj.pImg = dataChunck[i].addons[j].products[0].images?.[0];
                                newObj.name = dataChunck[i].addons[j].products[0].title;
                                newObj.description =
                                    dataChunck[i].addons[j].products[0].description;
                                newObj.image = dataChunck[i].addons[j].image;
                                products.push(newObj);
                            }
                        }
                    }
                    obj.products = products;
                    obj.senderName = orderObj[0].order.senderName;
                    obj.orderDate = orderObj[0].order.createdDate;
                    array.push(obj);
                }
            }
        });

        return array;
    };

    render() {
        return (
            <div>
                <Container>
                    <div>
                        <FormControl variant="outlined" style={{width: "100%"}}>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                // value={age}
                                // onChange={handleChange}
                                value={this.state.value}
                                onOpen={(e) => this.setState({displayDate: false})}
                                onChange={(e) => {
                                    // this.handleSelect(e.target.value);
                                    e.preventDefault();
                                    this.setState((prevState) => ({
                                        prevValue: prevState.value,
                                        value: e.target.value,
                                    }));
                                    console.log("this is value", e.target.value);
                                }}
                                onClose={(e) => {
                                    const {myValue} = e.currentTarget.dataset;
                                    if (myValue != "custom") {
                                        this.setState({
                                            startValue: "",
                                            endValue: "",
                                            showCustom: false,
                                        });
                                    } else {
                                        this.setState({showCustom: true});
                                    }
                                    this.getData(myValue);
                                    this.setState({displayDate: true});
                                }}
                                label="Age"
                            >
                                <MenuItem data-my-value="today" value={this.handleSelect(1)}>
                                    {this.state.displayDate ? this.state.value : "Today"}
                                </MenuItem>
                                <MenuItem data-my-value="month" value={this.handleSelect(2)}>
                                    {this.state.displayDate ? this.state.value : "This Month"}
                                </MenuItem>
                                <MenuItem
                                    data-my-value="prevMonth"
                                    value={this.handleSelect(3)}
                                >
                                    {this.state.displayDate ? this.state.value : "Last Month"}
                                </MenuItem>
                                <MenuItem data-my-value="custom" value={4}>
                                    {this.state.displayDate
                                        ? this.state.startValue + "-" + this.state.endValue
                                        : "Custom"}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {this.state.showCustom && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "400px",
                                width: "300px",
                                padding: "20px",
                                position: "absolute",
                                zIndex: 15,
                                backgroundColor: "#f6f4f5",
                            }}
                        >
                            <div>
                                <p style={{fontSize: 20}}>From:</p>
                                <TextField
                                    id="date"
                                    style={{width: "100%", display: "flex"}}
                                    type="date"
                                    onChange={(e) => {
                                        this.setState({
                                            start: e.target.value,
                                            startValue: e.target.value,
                                        });
                                    }}
                                    defaultValue="2017-05-24"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div>
                                <p style={{fontSize: 20}}>To:</p>
                                <TextField
                                    id="date"
                                    type="date"
                                    onChange={(e) => {
                                        this.setState({
                                            end: e.target.value,
                                            endValue: e.target.value,
                                        });
                                    }}
                                    style={{width: "100%", display: "flex"}}
                                    defaultValue="2017-05-24"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <Button
                                style={{width: "100%", display: "flex", marginTop: "10px"}}
                                variant="contained"
                                onClick={(e) => {
                                    this.getData(4);
                                    this.setState({showCustom: false});
                                }}
                            >
                                Ok
                            </Button>
                            <Button
                                variant="contained"
                                style={{width: "100%", display: "flex", marginTop: "10px"}}
                                onClick={(e) =>
                                    this.setState((prevState) => ({
                                        value: this.state.prevValue,
                                        startValue: "",
                                        endValue: "",
                                        showCustom: false,
                                    }))
                                }
                            >
                                Cancel
                            </Button>
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <h1 style={{color: "rgb(122, 187, 225)"}}>
                            {this.props.location.state.type} orders ({this.state.ids.length})
                        </h1>
                        <h3 style={{color: "#a1a1a1"}}>
                            Click on checkbox to Select. Click on SO number to view details
                        </h3>
                    </div>
                    {this.props.location?.state?.type !== "Delivered" && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                checked={this.state.allSelected}
                                onChange={(e) => this.allSelectedPressed(e)}
                                name="checkedB"
                                color="primary"
                            />
                            <p>Select All</p>
                        </div>
                    )}
                    {this.getSubOrders(this.props.orders)}
                </Container>
                {this.state.allocated && (
                    <div>
                        <Button
                            style={{
                                width: "100%",
                                bottom: 0,
                                left: 0,
                                backgroundColor: "#39b97b",
                                color: "white",
                                fontSize: 20,
                                display: "flex",
                                marginTop: "10px",
                                position: "fixed",
                            }}
                            onClick={(e) => this.handleAccept(e)}
                            variant="contained"
                        >
                            ACCEPT
                        </Button>
                    </div>
                )}
                {this.state.accepted && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            position: "fixed",
                            height: "100px",
                            bottom: 0,
                            left: 0,
                        }}
                    >
                        <Button
                            style={{
                                flex: 1,
                                backgroundColor: "#39b97b",
                                color: "white",
                                fontSize: 20,
                                display: "flex",
                                marginTop: "10px",
                            }}
                            onClick={(e) => this.handleAllocate(e, "allocate")}
                            variant="contained"
                        >
                            Allocate To Delivery Boy
                        </Button>
                        <Button
                            style={{
                                flex: 1,
                                backgroundColor: "#39b97b",
                                color: "white",
                                fontSize: 20,
                                display: "flex",
                                marginTop: "10px",
                            }}
                            onClick={(e) => this.handleAllocate(e, "out")}
                            variant="contained"
                        >
                            Out for delivery
                        </Button>
                        <Button
                            style={{
                                flex: 1,
                                backgroundColor: "#39b97b",
                                color: "white",
                                fontSize: 20,
                                display: "flex",
                                marginTop: "10px",
                            }}
                            onClick={(e) => this.printBulk(e)}
                            variant="contained"
                        >
                            Print Bulk Order Challan
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    state = {
        selected: false,
        number: 50,
        allSelected: false,
        value: "",
        displayDate: true,
        start: "",
        end: "",
        ids: [],
        orders: [],
        selectedIds: [],
        accepted: false,
        startValue: "",
        endValue: "",
        showCustom: false,
        allocated: false,
    };
    renderOrders = (orders) => {
        // let orders = this.state.orders;
        console.log("orders", orders);
        let lastIndex = orders.length - 1;
        let list2 = [];
        list2.push(
            orders &&
            orders.map((order, index) => {
                let paymentMethod = order.paymentMethod;
                let count = 1;
                let orderDate = order.createdDate;
                //let amount = order?.amount;
                let id = order?._id;

                let list = [];
                let carts = [];
                if (order.Cart) {
                    carts = JSON.parse(JSON.stringify(order.Cart));
                } else {
                    carts = JSON.parse(JSON.stringify(order.carts));
                }
                while (carts.length > 0) {
                    let orderId = id + "" + count;
                    count = count + 1;
                    let order = carts[0].deliveryAddress?._id
                        ? carts[0].deliveryAddress._id
                        : carts[0].address?._id;
                    let dataChunck = null;
                    if (carts[0].deliveryAddress?._id) {
                        dataChunck = carts.filter((e) => e.deliveryAddress._id === order);
                    } else {
                        dataChunck = carts.filter((e) => e.address._id === order);
                    }

                    dataChunck.forEach((f) =>
                        carts.splice(
                            carts.findIndex((e) => e._id === f._id),
                            1
                        )
                    );
                    let amount = 0;
                    let shipping = 0;
                    dataChunck.map((cart) => {
                        if (shipping < cart.shippingPrice) {
                            shipping = cart.shippingPrice;
                        }
                        amount = amount + cart?.amount * cart?.quantity;
                        cart.addons &&
                        cart.addons.map((addon) => {
                            amount =
                                amount +
                                addon.products[0]?.Price[0]?.price * addon.quantity;
                        });
                    });

                    list.push(
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                marginBottom:
                                    index == lastIndex && !(carts.length > 0) ? "110px" : "0",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            ></div>

                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",

                                    flexDirection: "column",
                                    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                                    borderColor: "#d9d7d7",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#7abbe1",
                                        display: "flex",
                                        height: "60px",
                                        justifyContent: "space-between",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                        alignItems: "center",
                                        padding: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {!(this.props.location.state.type == "Delivered") && (
                                            <WhiteBackgroundCheckbox
                                                checked={this.state.selectedIds.indexOf(id) !== -1}
                                                onChange={(e) => this.checkboxPressed(id, index)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        )}
                                        <p
                                            style={{color: "white", cursor: "pointer"}}
                                            onClick={(e) => {
                                                console.log("created Date", order);
                                                this.props.history.push({
                                                    pathname: "/orderDetails",
                                                    state: {
                                                        orderId: dataChunck[0]?.subOrderId
                                                            ? dataChunck[0].subOrderId
                                                            : orderId,
                                                        _id: id,
                                                        type: this.props.location.state.type,
                                                        start: this.state.start,
                                                        end: this.state.end,
                                                        orderDate: orderDate,
                                                        deliveryInfo: dataChunck[0]?.deliveryAddress
                                                            ? dataChunck[0]?.deliveryAddress
                                                            : dataChunck[0]?.address,
                                                        paymentMethod: paymentMethod,
                                                        shippingInfo: {
                                                            type: dataChunck[0]?.shippingType,
                                                            date: dataChunck[0]?.shippingDate,
                                                            time: dataChunck[0]?.shippingTime,
                                                            price: dataChunck[0]?.shippingPrice,
                                                        },
                                                        carts: dataChunck,
                                                    },
                                                });
                                            }}
                                        >
                                            {dataChunck[0]?.subOrderId
                                                ? dataChunck[0].subOrderId
                                                : orderId}
                                        </p>
                                    </div>
                                    <p style={{color: "white"}}>AED: {amount + shipping}</p>
                                </div>
                                <div
                                    style={{
                                        borderBottom: "2px solid",
                                        borderColor: "#d9d7d7",
                                    }}
                                >
                                    <div style={{marginTop: 20, marginLeft: 10}}>
                                        <h4>
                                            {dataChunck[0]?.deliveryAddress?.name
                                                ? dataChunck[0]?.deliveryAddress?.name
                                                : dataChunck[0]?.address?.name}
                                        </h4>
                                        <p style={{marginTop: 10, marginBottom: 20}}>
                                            {dataChunck[0]?.deliveryAddress?.recipientAddress
                                                ? dataChunck[0].deliveryAddress?.recipientAddress
                                                : dataChunck[0]?.address?.recipientAddress
                                                    ? dataChunck[0]?.address?.recipientAddress
                                                    : ""}{" "}
                                            {dataChunck[0]?.deliveryAddress?.landmark
                                                ? dataChunck[0].deliveryAddress?.landmark
                                                : dataChunck[0]?.address?.landmark
                                                    ? dataChunck[0]?.address?.landmark
                                                    : ""}{" "}
                                            {dataChunck[0]?.deliveryAddress?.recipientCity
                                                ? dataChunck[0].deliveryAddress?.recipientCity
                                                : dataChunck[0]?.address?.recipientCity
                                                    ? dataChunck[0]?.address?.recipientCity
                                                    : ""}{" "}
                                            {dataChunck[0]?.deliveryAddress?.phone
                                                ? dataChunck[0].deliveryAddress?.phone
                                                : dataChunck[0]?.address?.phone
                                                    ? dataChunck[0]?.address?.phone
                                                    : ""}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: 10,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <h4>{dataChunck[0]?.shippingType + ":"}</h4>
                                        <p>[AED. {dataChunck[0]?.shippingPrice} ]</p>
                                        <p style={{marginLeft: 10}}>
                                            |{" "}
                                            {moment(dataChunck[0]?.shippingDate)
                                                .utc(true)
                                                .format("Y-MM-DD")}{" "}
                                            |
                                        </p>
                                        <p style={{marginLeft: 10}}>
                                            {dataChunck[0]?.shippingTime}{" "}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "10px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    {dataChunck &&
                                    dataChunck.map((cart) => (
                                        <div sytle={{display: "flex", width: "100%"}}>
                                            <Row
                                                image={cart?.products?.[0]?.images?.[0]}
                                                name={cart?.products?.[0]?.title}
                                                id={cart?.products?.[0]?.productId}
                                                description={cart?.products?.[0]?.description}
                                                amount={
                                                    cart?.amount *
                                                    (cart?.quantity ? cart.quantity : 1)
                                                }
                                                quantity={cart?.quantity ? cart.quantity : 1}
                                            />
                                            {this.addons(cart.addons)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {this.props.location.state.type == "Delivered" && (
                                <div
                                    style={{
                                        backgroundColor: "#7abbe1",
                                        display: "flex",
                                        flexDirection: "row",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        borderBottomRightRadius: "5px",
                                        borderBottomLeftRadius: "5px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            fontSize: 15,
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {dataChunck[0]?.deliveryStatus
                                            ? dataChunck[0]?.deliveryStatus
                                            : "Pending"}
                                    </p>
                                    <p style={{color: "white", fontSize: 15}}>
                                        {dataChunck[0]?.receivedBy}
                                    </p>
                                    <p
                                        style={{
                                            color: "white",
                                            fontSize: 15,
                                            marginRight: "5px",
                                        }}
                                    >
                                        {dataChunck[0].deliveryStatus === "DELIVERED"
                                            ? moment(dataChunck[0]?.deliveryDate)
                                                .utc(true)
                                                .format("HH:mm")
                                            : dataChunck[0].lastAttempted
                                                ? moment(dataChunck[0]?.lastAttempted)
                                                    .utc(true)
                                                    .format("HH:mm")
                                                : ""}
                                    </p>
                                </div>
                            )}
                        </div>
                    );
                }
                return list;
            })
        );
        return list2;
    };
    addons = (addons) => {
        console.log("addons", addons);
        return (
            addons &&
            addons.map((addon) => (
                <Row
                    image={addon?.products[0]?.images?.[0]}
                    name={addon?.products[0]?.title}
                    id={addon?.products[0]?.productId}
                    description={addon?.products[0]?.description}
                    amount={addon.amount * (addon?.quantity ? addon.quantity : 1)}
                    quantity={addon?.quantity ? addon.quantity : 1}
                />
            ))
        );
    };

    getSubOrders = (orders) => {
        // let orders = this.state.orders;
        let lastIndex = orders.length - 1;
        let parentArray = [];
        orders &&
        orders.map((order, index) => {
            let amount = 0;
            let shipping = 0;
            order.map((o) => {
                amount = amount + o.amount * o.quantity;
                if (shipping < o.shippingPrice) {
                    shipping = o.shippingPrice;
                }
                if (o?.addons?.length > 0) {
                    o.addons.map((addon) => {
                        amount = amount + addon.amount * addon.quantity;
                    });
                }
            });
            amount = amount + shipping;
            parentArray.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        marginBottom: index == lastIndex ? "110px" : "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginTop: "20px",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    ></div>

                    <div
                        style={{
                            display: "flex",
                            width: "100%",

                            flexDirection: "column",
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                            borderColor: "#d9d7d7",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor:
                                    this.props.location.state.type !== "Delivered"
                                        ? "#7abbe1"
                                        : order[0].deliveryStatus &&
                                        order[0]?.deliveryStatus === "DELIVERED"
                                        ? "#7abbe1"
                                        : "#f99d33",
                                display: "flex",
                                height: "60px",
                                justifyContent: "space-between",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                alignItems: "center",
                                padding: "5px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {!(this.props.location.state.type == "Delivered") && (
                                    <input
                                        type="checkbox"
                                        style={{zoom: 1.3, marginRight: "4px"}}
                                        checked={this.state.ids[index]}
                                        onChange={(e) => this.checkboxPressed(order, index)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                )}
                                <p
                                    style={{color: "white", cursor: "pointer"}}
                                    onClick={(e) => {
                                        this.props.history.push({
                                            pathname: "/orderDetails",
                                            state: {
                                                orderId: order[0]?.subOrderId,
                                                _id: order[0].orderId,
                                                type: this.props.location.state.type,
                                                start: this.state.start,
                                                end: this.state.end,
                                                orderDate: order[0].order.createdDate,
                                                deliveryInfo: order[0]?.address,
                                                paymentMethod: order[0].order.paymentMethod,
                                                senderName: order[0].order.senderName,
                                                shippingInfo: {
                                                    type: order[0]?.shippingType,
                                                    date: order[0]?.shippingDate,
                                                    time: order[0]?.shippingTime,
                                                    price: order[0]?.shippingPrice,
                                                },
                                                carts: order,
                                            },
                                        });
                                    }}
                                >
                                    {order[0]?.subOrderId ? order[0].subOrderId : order.orderId}
                                </p>
                            </div>
                            <p style={{color: "white"}}>AED: {amount}</p>
                        </div>
                        <div
                            style={{
                                borderBottom: "2px solid",
                                borderColor: "#d9d7d7",
                            }}
                        >
                            <div style={{marginTop: 20, marginLeft: 10}}>
                                <h4>
                                    {order[0]?.deliveryAddress?.name
                                        ? order[0]?.deliveryAddress?.name
                                        : order[0]?.address?.name}
                                </h4>
                                <p style={{marginTop: 10, marginBottom: 20}}>
                                    {order[0]?.deliveryAddress?.recipientAddress
                                        ? order[0].deliveryAddress?.recipientAddress
                                        : order[0]?.address?.recipientAddress
                                            ? order[0]?.address?.recipientAddress
                                            : ""}{" "}
                                    {order[0]?.deliveryAddress?.landmark
                                        ? order[0].deliveryAddress?.landmark
                                        : order[0]?.address?.landmark
                                            ? order[0]?.address?.landmark
                                            : ""}{" "}
                                    {order[0]?.deliveryAddress?.recipientCity
                                        ? order[0].deliveryAddress?.recipientCity
                                        : order[0]?.address?.recipientCity
                                            ? order[0]?.address?.recipientCity
                                            : ""}{" "}
                                    {order[0]?.deliveryAddress?.phone
                                        ? order[0].deliveryAddress?.phone
                                        : order[0]?.address?.phone
                                            ? order[0]?.address?.phone
                                            : ""}
                                </p>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: 10,
                                    marginBottom: 20,
                                }}
                            >
                                <h4>{order[0]?.shippingType + ":"}</h4>
                                <p>[AED. {shipping} ]</p>
                                <p style={{marginLeft: 10}}>
                                    |{" "}
                                    {moment(order[0]?.shippingDate).utc(true).format("Y-MM-DD")}{" "}
                                    |
                                </p>
                                <p style={{marginLeft: 10}}>{order[0]?.shippingTime} </p>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px",
                                marginBottom: "20px",
                            }}
                        >
                            {order &&
                            order.map((cart) => (
                                <div sytle={{display: "flex", width: "100%"}}>
                                    <Row
                                        image={cart?.products?.[0]?.images?.[0]}
                                        name={cart?.products?.[0]?.title}
                                        id={cart?.products?.[0]?.productId}
                                        description={cart?.products?.[0]?.description}
                                        amount={
                                            cart?.amount * (cart?.quantity ? cart.quantity : 1)
                                        }
                                        quantity={cart?.quantity ? cart.quantity : 1}
                                    />
                                    {this.addons(cart.addons)}
                                </div>
                            ))}
                        </div>
                    </div>
                    {this.props.location.state.type == "Delivered" && (
                        <div
                            style={{
                                backgroundColor:
                                    order[0]?.deliveryStatus === "DELIVERED"
                                        ? "#7abbe1"
                                        : "#f99d33",
                                display: "flex",
                                flexDirection: "row",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                borderBottomRightRadius: "5px",
                                borderBottomLeftRadius: "5px",
                                justifyContent: "space-between",
                            }}
                        >
                            <p
                                style={{
                                    color: "white",
                                    fontSize: 15,
                                    marginLeft: "5px",
                                }}
                            >
                                {order[0]?.deliveryStatus
                                    ? order[0]?.deliveryStatus
                                    : "Pending"}
                            </p>
                            <p style={{color: "white", fontSize: 15}}>
                                {order[0]?.receivedBy
                                    ? order[0].receivedBy
                                    : order[0]?.reason
                                        ? order[0]?.reason
                                        : ""}
                                {order[0]?.receivedBy === null && order[0]?.remarks
                                    ? `[${order[0].remarks}]`
                                    : ""}
                            </p>
                            <p
                                style={{
                                    color: "white",
                                    fontSize: 15,
                                    marginRight: "5px",
                                }}
                            >
                                {order[0].deliveryStatus === "DELIVERED"
                                    ? moment(order[0]?.deliveryDate).utc(true).format("HH:mm")
                                    : order[0].lastAttempted
                                        ? moment(order[0]?.lastAttempted).utc(true).format("HH:mm")
                                        : ""}
                            </p>
                        </div>
                    )}
                </div>
            );
        });
        return parentArray;
    };
    checkboxPressed = (order, index) => {
        let selectedIds = this.state.selectedIds;
        let ids = this.state.ids;
        if (ids[index]) {
            selectedIds = selectedIds.filter((s) => s !== order[0].subOrderId);
        } else {
            selectedIds.push(order[0].subOrderId);
        }
        ids[index] = !ids[index];

        this.setState({ids, selectedIds});
    };
    allSelectedPressed = async () => {
        let ids = this.state.ids;
        let orders = this.props.orders;
        let selectedIds = [];
        if (!this.state.allSelected) {
            selectedIds = this.props.orders.map((order) => {
                return order[0].subOrderId;
            });
            ids = ids.map((id) => true);
        } else {
            ids = ids.map((id) => false);
        }
        this.setState({ids, allSelected: !this.state.allSelected, selectedIds});
    };
    handleSelect = (value) => {
        if (value == 1) {
            var now = moment();
            var start = now.startOf("day").format("YYYY-MM-DD h:mm:ss");
            var end = now.endOf("day").format("YYYY-MM-DD h:mm:ss");

            return start.toString() + " - " + end.toString();
        } else if (value == 2) {
            var now = moment();
            var start = now.startOf("month").format("YYYY-MM-DD h:mm:ss");
            var end = now.endOf("month").format("YYYY-MM-DD h:mm:ss");

            return start.toString() + " - " + end.toString();
        } else if (value == 3) {
            var now = moment();
            var start = now
                .clone()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD h:mm:ss");
            var end = now
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD h:mm:ss");

            return start.toString() + " - " + end.toString();
        } else if (value == 4) {
        }
    };
    getData = (type) => {
        if (type == "today") {
            let newNow = moment();
            var start = newNow.startOf("day").toDate();
            var end = newNow.endOf("day").toDate();
            const date = new Date();
            const offset = date.getTimezoneOffset();
            if (this.props.location.state.type === "Allocated") {
                this.props.getAllocated({start: start, end: end, offset});
                this.setState({end: end, start: start});
            }
            if (this.props.location.state.type === "Accepted") {
                this.props.getOrders({start: start, end: end, offset});
                this.setState({end: end, start: start});
            }
            if (this.props.location.state.type === "Delivered") {
                this.props.getDelivered({start: start, end: end, offset});
                this.setState({end: end, start: start});
            }
        }
        if (type == "month") {
            let newNow = moment();
            var startTime = newNow.startOf("month").toDate();
            var endTime = newNow.endOf("month").toDate();
            const date = new Date();
            const offset = date.getTimezoneOffset();
            if (this.props.location.state.type === "Allocated") {
                this.props.getAllocated({start: startTime, end: endTime, offset});
                this.setState({end: endTime, start: startTime});
            }
            if (this.props.location.state.type === "Accepted") {
                this.props.getOrders({start: startTime, end: endTime, offset});
                this.setState({end: endTime, start: startTime});
            }
            if (this.props.location.state.type === "Delivered") {
                this.props.getDelivered({start: startTime, end: endTime, offset});
                this.setState({end: endTime, start: startTime});
            }
        }
        if (type == "prevMonth") {
            let newNow = moment();
            var startTime = newNow.startOf("month").add(-1, "months").toDate();
            var endTime = newNow.endOf("month").toDate();
            const date = new Date();
            const offset = date.getTimezoneOffset();
            if (this.props.location.state.type === "Allocated") {
                this.props.getAllocated({start: startTime, end: endTime, offset});
                this.setState({end: endTime, start: startTime});
            }
            if (this.props.location.state.type === "Accepted") {
                this.props.getOrders({start: startTime, end: endTime, offset});
                this.setState({end: endTime, start: startTime});
            }
            if (this.props.location.state.type === "Delivered") {
                this.props.getDelivered({start: startTime, end: endTime, offset});
                this.setState({end: endTime, start: startTime});
            }
        }
        if (type == 4) {
            const date = new Date();
            const offset = date.getTimezoneOffset();
            if (this.props.location.state.type === "Allocated") {
                this.props.getAllocated({
                    start: this.state.start,
                    end: this.state.end,
                    offset,
                });
            }
            if (this.props.location.state.type === "Accepted") {
                this.props.getOrders({
                    start: this.state.start,
                    end: this.state.end,
                    offset,
                });
            }
            if (this.props.location.state.type === "Delivered") {
                this.props.getDelivered({
                    start: this.state.start,
                    end: this.state.end,
                    offset,
                });
            }
        }
    };
    handleAccept = (e) => {
        e.preventDefault();
        let subOrderIds = this.state.selectedIds;
        this.setState({allSelected: false});
        let acceptedDate = new Date();
        this.props.setParty({
            acceptedDate,
            subOrderIds,
            start: this.state.start,
            end: this.state.end,
            offset: "",
        });
    };
    componentDidMount = async () => {
        this.setState({value: this.handleSelect(1)});
        if (this.props.location?.state?.type === "Accepted") {
            this.props.setScreen("Accepted");
            let end = this.props.location.state.end
                ? this.props.location.state.end
                : "";
            if (this.props.location.state.orderId) {
                this.props.getOrder(this.props.location.state.orderId);
            } else {
                await this.props.getOrders({
                    start: this.props.location.state.start,
                    end: end,
                    offset: this.props.location.state.offset,
                });
            }
            this.setState({
                accepted: true,
                end,
                start: this.props.location.state.start,
            });
        }
        if (this.props.location?.state?.type === "Allocated") {
            this.props.setScreen("Allocated");
            let end = this.props.location.state.end
                ? this.props.location.state.end
                : "";
            this.setState({
                end,
                start: this.props.location.state.start,
                allocated: true,
            });
            if (this.props.location.state.orderId) {
                let a = await this.props.getOrder(this.props.location.state.orderId);
            } else {
                await this.props.getAllocated({
                    start: this.props.location.state.start,
                    end: end,
                    offset: this.props.location.state.offset,
                });
            }
        }
        if (this.props.location?.state?.type === "Delivered") {
            this.props.setScreen("Delivered");
            if (this.props.location.state.orderId) {
                let a = await this.props.getOrder(this.props.location.state.orderId);
            } else {
                await this.props.getDelivered({
                    start: this.props.location.state.start,
                    end: this.props.location.state.end,
                    offset: this.props.location.state.offset,
                });
            }
        }
    };
    // componentWillReceiveProps = (nextProps, nextContext) => {
    //
    //   if (nextProps.orders.length != this.state.ids.length) {
    //     let state = [];
    //     let orders = nextProps.orders;
    //     orders.map((order) => {
    //       state.push(false);
    //     });
    //
    //     this.setState({ ids: state, orders: orders });
    //   }
    // };

    componentDidUpdate(prevProps) {
        if (prevProps.orders !== this.props.orders) {
            let orders = this.props.orders;
            const state = orders.map((order) => {
                return false;
            });
            this.setState({ids: state, orders: orders});
        }
        if (prevProps.location?.state?.type !== this.props.location?.state?.type) {
            window.location.reload(false);
        }
    }

    handleAllocate = (e, type) => {
        let selectedIds = this.state.selectedIds;
        this.props.history.push({
            pathname: "/delivery-boys",
            state: {
                subOrderIds: selectedIds,
                type: type,
            },
        });
    };
}

const mapStateToProps = (state) => {
    return {
        orders: state.auth.orders,
    };
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        getOrders: (data) => dispatch(getOrders(data, router)),
        getAllocated: (data) => dispatch(getAllocated(data, router)),
        setParty: (data) => dispatch(setParty(data, router)),
        getDelivered: (data) => dispatch(getDelivered(data, router)),
        getImage: (data) => dispatch(getImage(data, router)),
        setScreen: (data) => dispatch({type: "SCREEN", payload: data}),
        getOrder: (data) => dispatch(getOrder(data, router)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ListOrders)
);
