import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FilledInput,
  TextField,
  Button,
  Menu,
  MenuItem,
  makeStyles,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import "./style.scss";
import logo from "../../../assets/images/logo.png";
import mobileSprite from "../../../assets/images/mobile-sprite.png";

import clsx from "clsx";
// import { makeStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const TemporaryDrawer = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [menuItems] = React.useState([
    { key: "1", title: "Home", to: "/other" },
    { key: "2", title: "Order", to: "/orders " },
  ]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {menuItems.map((item) => (
        <List>
          <ListItem component={Link} to={item.to} key={item.key}>
            <ListItemText primary={item.title} />
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      ))}
      {/* <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemText primary={text} />
                        <ListItemIcon>
                            <NavigateNextIcon />
                        </ListItemIcon>
                    </ListItem>
                ))}
            </List> */}
    </div>
  );

  return (
    <div>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
      <React.Fragment>
        <Button
          onClick={toggleDrawer("left", true)}
          style={{ background: `url(${mobileSprite}) no-repeat 1px 8px` }}
          className="ham"
        ></Button>

        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
};

class NavBar extends Component {
  state = {
    searchBar: true,
    menuState: false,
  };

  inputStyle = { background: "#fff" };

  buttonStyles = {
    height: "100%",
    background: "#2199e8",
    borderRadius: "0px",
    color: "#fff",
  };

  toggleSearchBar = (e) => {
    e.preventDefault();
    this.setState({ searchBar: !this.state.searchBar });
  };

  handleClose = () => this.setState({ menuState: !this.state.menuState });

  menuToggle = (e) => {
    e.preventDefault();
    this.handleClose();
  };

  render() {
    return (
      <nav>
        <div style={{ display: "flex" }}>
          {/* <a
                        href="#"
                        className="ham"
                        onClick={this.menuToggle}
                        style={{ background: `url(${mobileSprite}) no-repeat 1px 8px` }}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                    ></a> */}
          {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        Open Menu
                    </Button> */}
          <TemporaryDrawer />
          {/* <Menu
                        id="simple-menu"
                        anchorEl={this.state.menuState}
                        keepMounted
                        open={this.state.menuState}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                        <MenuItem onClick={this.handleClose}>My account</MenuItem>
                        <MenuItem onClick={this.handleClose}>Logout</MenuItem>
                    </Menu> */}
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="seach-bar"
            style={{ display: `${!this.state.searchBar ? "none" : "block"}` }}
          >
            <TextField
              variant="outlined"
              style={this.inputStyle}
              placeholder="Enter Order Id"
            />
            <Button
              variant="contained"
              style={this.buttonStyles}
              onClick={this.onLogin}
            >
              GO
            </Button>
          </div>
          <a
            href="#"
            className="ham"
            onClick={this.toggleSearchBar}
            style={{
              background: `url(${mobileSprite}) no-repeat -29px 3px`,
              marginRight: "12px",
            }}
          ></a>
          <span>
            <p>AE- FNP RAJESH . </p>
            <p>fnpnmore@gmail.com</p>
          </span>
        </div>
      </nav>
    );
  }
}

export default NavBar;
