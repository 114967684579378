import axios from "axios";
import { Notify, redirect } from "../../constants/utils";
const SERVICE_URL = process.env.REACT_APP_BACKEND_URL;
export const dispatchAction = (action, payload, route = null) => (
  dispatch,
  router
) => {
  dispatch({ type: action, payload: payload });
  if (route) {
    redirect(router, route);
  }
};
export const postRequest = async (
  url,
  body = null,
  notify = true,
  headers = {}
) => {
  var finalUrl = SERVICE_URL + url;

  return new Promise((resolve, reject) => {
    axios
      .post(finalUrl, body, { headers: headers })
      .then(function (response) {
        resolve(response.data);
        if (notify) {
          Notify("success", response?.data?.message);
        }
      })
      .catch(function (error) {
        if (notify && error.response) {
          Notify("danger", error.response?.data?.message);
        }
        reject(error);
      });
  });
};
export const getRequest = async (
  url,
  body = null,
  notify = true,
  headers = {}
) => {
  console.warn("getBody", body);
  var finalUrl = SERVICE_URL + url;
  return new Promise((resolve, reject) => {
    axios
      .get(finalUrl, { params: { ...body } }, { headers: { ...headers } })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (notify && error.response) {
          Notify("danger", error.response?.data?.message);
        }
        reject(error);
      });
  });
};
export const putRequest = async (
  url,
  body = null,
  notify = false,
  headers,
  isBack = true
) => {
  console.warn("putBody", body);
  var finalUrl = "";
  finalUrl = isBack ? SERVICE_URL + url : url;
  return new Promise((resolve, reject) => {
    axios
      .put(finalUrl, body, { headers: { ...headers } })
      .then(function (response) {
        resolve(response.data);
        if (notify && response.data?.message) {

        }
      })
      .catch(function (error) {
        if (error.response) {
          Notify("danger", error.response?.data?.message);
        }
        reject(error);
      });
  });
};
export const deleteRequest = async(
    url,
    body = null,
    notify = false,
    headers,
    isBack = true
) => {
  var finalUrl = "";
  finalUrl = isBack ? SERVICE_URL + url : url;
  return new Promise((resolve, reject) => {
    axios
        .delete(finalUrl, body, { headers: { ...headers } })
        .then(function (response) {
          resolve(response.data);
          if (notify && response.data?.message) {

          }
        })
        .catch(function (error) {
          if (error.response) {
            Notify("danger", error.response?.data?.message);
          }
          reject(error);
        });
  });
}
