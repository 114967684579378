import React from "react";
import { TextField, Button, Container } from "@material-ui/core";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { updateDeliveryBoyPassword } from "../../store/actions/authActions";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import "./style.scss";
import avatar from "../../assets/images/avatar.png";

// const useStyles = makeStyles({
//     root: {
//         minWidth: 275,
//     },
//     bullet: {
//         display: 'inline-block',
//         margin: '0 2px',
//         transform: 'scale(0.8)',
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },
// });

class editPassword extends React.Component {
  // classes = useStyles();
  // bull = <span className={this.classes.bullet}>•</span>;
  componentDidMount() {
    let { _id } = this.props.location.state.detail;
    this.setState({ _id });
  }
  state = {
    password: "",
    confirmPassword: "",
    _id: "",
    show: false,
    passwordEmpty: false,
  };

  inputStyle = { background: "#fff" };

  buttonStyles = {
    height: "100%",
    background: "#0f9d58",
    borderRadius: "0px",
    color: "#fff",
    padding: "5px",
  };

  render() {
    return (
      <Container>
        <div className="delivery-boi__header">
          <div></div>
          <h1 style={{ color: "#63b9d9", fontWeight: "500" }}>Edit Profile</h1>
          <div></div>
        </div>

        <Card
          style={{
            width: "100%",
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            marginTop: "10px",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.05)",
              border: "1px solid #999",

              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <img
                  src={avatar}
                  alt="avatar"
                  className="delivery-boi__avatar"
                />

                <div
                  className="delivery-boi__info"
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      value={this.state.password}
                      label="New Password"
                      type="password"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      style={{ width: "100%" }}
                    />
                    <TextField
                      id="standard-basic"
                      value={this.state.name}
                      type="password"
                      onChange={(e) =>
                        this.setState({ confirmPassword: e.target.value })
                      }
                      label="ReType Password"
                      style={{ width: "100%" }}
                    />
                    {this.state.show && (
                      <p
                        style={{
                          fontSize: 15,
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        Password does not match
                      </p>
                    )}
                    {this.state.passwordEmpty && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        Password field is empty.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="delivery-boi__actions"
                style={{ marginTop: "20px" }}
              >
                {/* <Button variant="outlined" color="primary">
                  Change Password
                </Button> */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => this.props.history.goBack()}
                >
                  Cancel
                </Button>

                <Button
                  variant="outlined"
                  style={{ backgroundColor: "#f37f0a", color: "white" }}
                  onClick={(e) => this.handleUpdate()}
                >
                  Save
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    );
  }
  handleUpdate = () => {
    // this.props.update({ name, countryCode, mobileNumber, _id, partyId });
    // this.props.history.goBack();
    const { _id, password, confirmPassword } = this.state;
    if (password.trim() !== "") {
      if (password == confirmPassword) {
        this.props.update({ id: _id, password });
        this.props.history.goBack();
      } else {
        this.setState({ show: true });
        setTimeout(() => {
          this.setState({ show: false });
        }, 4000);
      }
    } else {
      this.setState({ passwordEmpty: true });
      setTimeout(() => {
        this.setState({ passwordEmpty: false });
      }, 4000);
    }
  };
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    update: (data) => dispatch(updateDeliveryBoyPassword(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(editPassword)
);
