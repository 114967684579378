import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import logo from "../../assets/images/siteLogo.jpg";
import mobileSprite from "../../assets/images/mobile-sprite.png";
import { logout } from "../../store/actions/authActions";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getOrder } from "../../store/actions/authActions";
import { Notify } from "../../constants/utils";
import moment from "moment";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#0f9d58",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  ham: {
    display: "block",
    width: "10px",
    height: "40px",
  },
  inputStyle: {
    background: "#fff",
  },
  buttonStyles: {
    height: "100%",
    background: "#2199e8",
    borderRadius: "0px",
    color: "#fff",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [searchBar, toggleSearchBar] = useState(false);
  const [text, setText] = useState("");

  const deliveryBoysId = localStorage.getItem("deliveryBoyId");
  const [openProfile, setOpenProfile] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClick = () => {
    setOpenProfile(!openProfile);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const setTextOfMessage = (msg) => {
    setMessage(msg);
    setTimeout(function () {
      setMessage("");
    }, 4000);
  };
  const handleSearch = async () => {
    if (text === "") {
      setTextOfMessage("Please enter a sub order id.");
      return;
    }
    let abc = await props.getOrder(text);
    let carts = abc.data;
    let type = "";
    let now = moment();
    var start = now.startOf("day").toDate();
    var end = now.endOf("day").toDate();
    const date = new Date();
    let partyId = localStorage.getItem("partyId");
    let deliveryBoyId = localStorage.getItem("deliveryBoyId");
    if (carts.length > 0 && partyId) {
      if (carts[0].deliveryStatus === "DELIVERED") {
        type = "Delivered";
      } else if (carts[0].partyId) {
        type = "Accepted";
      } else if (!carts[0].partyId) {
        type = "Allocated";
      }

      history.push({
        pathname: "/orderDetails",
        state: {
          orderId: text,
          _id: carts[0]?.order?._id,
          type: type,
          start: start,
          end: end,
          orderDate: carts[0]?.order?.createdDate,
          deliveryInfo: carts[0]?.address,
          paymentMethod: carts[0]?.order?.paymentMethod,
          senderName: carts[0]?.order?.senderName,
          shippingInfo: {
            type: carts[0]?.shippingType,
            date: carts[0]?.shippingDate,
            time: carts[0]?.shippingTime,
            price: carts[0]?.shippingPrice,
          },
          carts: carts,
        },
      });
    } else if (carts.length > 0 && deliveryBoyId) {
      if (carts[0].deliveryStatus === null) {
        props.history.push({
          pathname: "/ShowPendingOrders",
          state: {
            type: "pending",
            carts: [carts],
          },
        });
      } else if (carts[0].deliveryStatus === "OUT_FOR_DELIVERY") {
        props.history.push({
          pathname: "/ShowPendingOrders",
          state: {
            type: "out",
            carts: [carts],
          },
        });
      } else if (carts[0].deliveryStatus === "ATTEMPTED") {
        props.history.push({
          pathname: "/ShowPendingOrders",
          state: {
            type: "attempted",
            carts: [carts],
          },
        });
      } else if (carts[0].deliveryStatus === "DELIVERED") {
        props.history.push({
          pathname: "/ShowPendingOrders",
          state: {
            type: "total",
            carts: [carts],
          },
        });
      }
    } else {
      setTextOfMessage("No suborder found with the given suborder id");
      return;
    }
  };
  const onSignOut = () => {
    // console.log(history);
    dispatch(logout(history));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap>
            Persistent drawer
        </Typography> */}
            <img
              src={logo}
              alt="logo"
              className="logo"
              style={{ width: "50px", height: "auto" }}
            />
          </div>
          {message !== "" && (
            <div style={{ backgroundColor: "red", borderRadius: "3px" }}>
              <p
                style={{
                  marginBottom: "0px",
                  margin: "5px",
                }}
              >
                {message}
              </p>
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {searchBar && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="seach-bar"
                  style={{
                    display: "flex",
                    height: "50px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    // variant="outlined"
                    // style={this.inputStyle}
                    style={{ height: "30px" }}
                    onChange={(e) => setText(e.target.value)}
                    type="number"
                    placeholder="Enter sub order Id"
                  />
                  <Button
                    onClick={() => handleSearch()}
                    variant="contained"
                    style={{
                      display: "flex",
                      height: "30px",
                      marginLeft: "10px",
                      backgroundColor: "#2199e8",
                    }}
                    // style={this.buttonStyles}
                    // onClick={this.onLogin}
                  >
                    GO
                  </Button>
                </div>
              </div>
            )}
            <SearchIcon
              style={{
                display: "flex",
                marginRight: "10px",
                marginLeft: "10px",
              }}
              onClick={() => toggleSearchBar(!searchBar)}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {!deliveryBoysId ? (
            <ListItem button component={Link} to="/">
              <ListItemText primary={"Home"} />
            </ListItem>
          ) : (
            ""
          )}

          {!deliveryBoysId ? (
            <ListItem
              button
              component={Link}
              target="_blank"
              to="/fulFilledReports"
            >
              <ListItemText primary={"Fulfilled Reports"} />
            </ListItem>
          ) : (
            ""
          )}
          {/* {!deliveryBoysId ? (
            <ListItem button component={Link} to="/fulFilledReports">
              <ListItemText primary={"Fulfilled Reports"} />
              <NavigateNextIcon />
            </ListItem>
          ) : (
            ""
          )} */}

          {/* <ListItem button onClick={onSignOut}>
            <ListItemText primary={'Sign Out'} />
            <NavigateNextIcon />
          </ListItem> */}

          <ListItem button onClick={handleClick}>
            {/* <ListItemIcon>
              <InboxIcon />
            </ListItemIcon> */}
            <ListItemText primary="My Account" />
            {openProfile ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openProfile} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {!deliveryBoysId ? (
                <ListItem
                  button
                  component={Link}
                  to="/Orders"
                  className={classes.nested}
                >
                  <ListItemText primary="My Orders" />
                  {/* <ListItemText primary="Profile" />
                <ListItemText primary="Delivery Boys" />
                <ListItemText primary="Sign Out"onClick={onSignOut} /> */}
                </ListItem>
              ) : (
                ""
              )}

              {!deliveryBoysId ? (
                <ListItem
                  button
                  className={classes.nested}
                  component={Link}
                  to="/profile"
                >
                  <ListItemText primary="Profile" />
                  {/* <NavigateNextIcon /> */}
                </ListItem>
              ) : (
                ""
              )}

              {!deliveryBoysId ? (
                <ListItem
                  button
                  className={classes.nested}
                  component={Link}
                  to="/delivery-boys"
                >
                  <ListItemText primary="Delivery Boys" />
                  {/* <NavigateNextIcon /> */}
                </ListItem>
              ) : (
                ""
              )}
              {!deliveryBoysId ? (
                <ListItem
                  button
                  className={classes.nested}
                  component={Link}
                  to="/editShipperPassword"
                >
                  <ListItemText primary="Change Password" />
                  {/* <NavigateNextIcon /> */}
                </ListItem>
              ) : (
                ""
              )}

              <ListItem button className={classes.nested}>
                <ListItemText primary="Sign Out" onClick={onSignOut} />
                {/* <NavigateNextIcon /> */}
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    screen: state.auth.screen,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    getOrder: (data) => dispatch(getOrder(data, router)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersistentDrawerLeft)
);
