import React, { Component, Fragment } from "react";
import { Container, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import "./style.scss";
import logo from "../../assets/images/siteLogo.jpg";
import { loginNow } from "../../store/actions/authActions";

const Header = () => {
  return (
    <header className="login__header">
      <img src={logo} alt="logo" style={{ width: "50px", height: "auto" }} />
    </header>
  );
};

class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  buttonStyles = {
    marginTop: "30px",
    background: "#2199e8",
    borderRadius: "0px",
    color: "#fff",
  };

  onLogin = () => {
    this.props.login({
      email: this.state.username,
      password: this.state.password,
    });
  };

  render() {
    return (
      <div className="login">
        <Header />
        <h1 className="login-h1">Login</h1>
        <Container maxWidth="lg">
          <TextField
            type="text"
            // label="Name"
            placeholder="User Name"
            name="name"
            style={{ width: "100%" }}
            value={this.state.username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />

          <TextField
            type="password"
            // label="Name"
            placeholder="Password"
            name="name"
            style={{ width: "100%", marginTop: "20px" }}
            value={this.state.password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          {this.props.password && (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Incorrect username or password.
            </p>
          )}

          <Button
            variant="contained"
            style={this.buttonStyles}
            onClick={this.onLogin}
          >
            LOGIN
          </Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    password: state.auth.password,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    login: (data) => dispatch(loginNow(data, router)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
