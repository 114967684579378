import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { connect } from "react-redux";
import { redirect } from "../../constants/utils";
import { updateDeliveryStatus } from "../../store/actions/authActions";
import StarIcon from "@material-ui/icons/Star";
import SignaturePad from "react-signature-canvas";
import { postRequest } from "../../store/services/index";

class Delivered extends Component {
  state = {
    value: "",
    receivedBy: "",
    ratingValue: null,
    hover: null,
    trimmedDataURL: null,
    signatureImage: "",
    imageRef: React.createRef(),
    error:false,
    deliveredImages:'',
  };
  inputHandler = async () => {
    let deliverdImagesString="";
    for(let i=0;i<this.state.images.length;i++){
      const file =this.state.images[i];
      const fileType = file.type;
      this.setState({ show: false });
        this.setState({ file });
        let formData = new FormData();
        formData.append("image", file);
        let image = await postRequest("cart/uploadImage", formData);
        let location = image.data;
        if (location) {
          if(deliverdImagesString.length<1){
            deliverdImagesString = deliverdImagesString+location;
          }
          else{
            deliverdImagesString = deliverdImagesString+";"+location;
          }
        }
    }
    debugger;
    await this.setState({deliveredImages:deliverdImagesString});
  };
  multipleImageHandler = (e) => {
    if(e.target.files.length>3){
      this.setState({error:true});
      this.state.imageRef.current.value = "";
      setTimeout(()=>{ this.setState({error:false}); }, 4000);
    }
    else{
      this.setState({ images: [...e.target.files] });
    }

  };
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  sigPad = {};
  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  submit = async () => {
    let token = localStorage.getItem("token");
   await this.inputHandler();
   let deliveredImages = this.state.deliveredImages
    let file = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    if (file) {
      var blob = this.dataURItoBlob(file);
      var fd = new FormData(document.forms[0]);
      fd.append("image", blob);
      let uploadedImage = await postRequest("cart/uploadImage", fd, false, {
        "access-token": token,
      });
      let location = uploadedImage.data;
      if (location) {
        this.setState({ signatureImage: location });
      }
    }
    let obj={};
    if(deliveredImages.length>0){
      obj.deliveredImages=deliveredImages;
    }
    this.props.updateDeliveryStatus({
      status: "DELIVERED",
      receivedBy: this.state.receivedBy,
      relation: this.state.value,
      signatureImage: this.state.signatureImage,
      rating: this.state.ratingValue,
      subOrderId: this.props.location.state.subOrderId,
      ...obj
    });
  };
  render() {
    let { trimmedDataURL } = this.state;
    return (
      <Container>
        <FormControl variant="outlined" style={{ display: "flex", flex: 1 }}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
            label="Age"
          >
            <MenuItem value={"SELF"}>Self</MenuItem>
            <MenuItem value={"FAMILY"}>Family</MenuItem>
            <MenuItem value={"NEIGHBOUR"}>Neighbour</MenuItem>
            <MenuItem value={"COLLEAGUE"}>Colleague</MenuItem>
            <MenuItem value={"OTHERS"}>Others</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          label="Recieved By"
          onChange={(e) => this.setState({ receivedBy: e.target.value })}
          style={{ display: "flex", marginTop: "20px", flex: 1 }}
          variant="outlined"
        />
        <p style={{ marginTop: "10px" }}>Rate Us</p>
        <div>
          {[...Array(5)].map((star, i) => {
            let ratingValue = i + 1;
            return (
              <label>
                <input
                  type="radio"
                  name="rating"
                  style={{ display: "none" }}
                  value={this.state.ratingValue}
                  onClick={(e) => this.setState({ ratingValue: ratingValue })}
                />
                <StarIcon
                  style={{
                    color:
                      ratingValue <=
                      (this.state.hover || this.state.ratingValue)
                        ? "#ffc107"
                        : "#e4e5e9",
                  }}
                  onMouseEnter={() => this.setState({ hover: ratingValue })}
                  onMouseLeave={() => this.setState({ hover: null })}
                />
              </label>
            );
          })}
        </div>

        {/*Quality photos start*/}
        <div style={{display:'flex',marginTop:'20px',marginBottom:'20px'}}>
        <p style={{marginRight:'10px'}}>Please upload photos for quality assurance:</p>

        <input
            type="file"
            name="images"
            id="images"
            ref={this.state.imageRef}
            multiple
            onChange={this.multipleImageHandler}
        />
          {this.state.error && <p style={{color: 'red', fontWeight: 'bold'}}>You cannot select more then 3 images</p>}
        </div>
        {/*Quality photos end*/}

        <p>Please sign here</p>
        <div style={{ width: "100%", height: "100vh" }}>
          <div
            style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}
          >
            <SignaturePad
              // penColor="black"
              canvasProps={{
                style: {
                  width: "100%",
                  height: "100%",
                },
              }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          </div>

          {/* {trimmedDataURL ? (
            <img
              width="200px"
              height="50px"
              backgroundColor="white"
              src={trimmedDataURL}
            />
          ) : null} */}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button style={{ backgroundColor: "#21ad69" }} onClick={this.clear}>
            Clear
          </Button>
          {/* <button onClick={this.trim}>Trim</button> */}
        </div>
        {/*dkfdkfj*/}
        <div
          onClick={(e) => this.submit()}
          style={{
            cursor: "pointer",
            backgroundColor: "#21ad69",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <p style={{ color: "white", fontSize: 30, display: "flex" }}>
            SUBMIT
          </p>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    updateDeliveryStatus: (data) =>
      dispatch(updateDeliveryStatus(data, router)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Delivered)
);
