import React from "react";
import { TextField, Button, Container } from "@material-ui/core";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { updateDeliveryBoy } from "../../store/actions/authActions";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import "./style.scss";
import avatar from "../../assets/images/avatar.png";

// const useStyles = makeStyles({
//     root: {
//         minWidth: 275,
//     },
//     bullet: {
//         display: 'inline-block',
//         margin: '0 2px',
//         transform: 'scale(0.8)',
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },
// });

class editDeliveryBoi extends React.Component {
  // classes = useStyles();
  // bull = <span className={this.classes.bullet}>•</span>;
  componentDidMount() {
    let {
      _id,
      name,
      countryCode,
      mobileNumber,
      userName,
      partyId,
    } = this.props.location.state.detail;
    this.setState({ name, countryCode, mobileNumber, userName, _id, partyId });
  }
  state = {
    _id: "",
    name: "",
    countryCode: "",
    mobileNumber: "",
    userName: "",
    partyId: "",
  };

  inputStyle = { background: "#fff" };

  buttonStyles = {
    height: "100%",
    background: "#0f9d58",
    borderRadius: "0px",
    color: "#fff",
    padding: "5px",
  };

  render() {
    return (
      <Container>
        <div className="delivery-boi__header">
          <div></div>
          <h1 style={{ color: "#63b9d9", fontWeight: "500" }}>Edit Profile</h1>
          <div></div>
        </div>

        <Card
          style={{
            width: "100%",
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            marginTop: "10px",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.05)",
              border: "1px solid #999",

              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <img
                  src={avatar}
                  alt="avatar"
                  className="delivery-boi__avatar"
                />

                <div
                  className="delivery-boi__info"
                  style={{
                    display: "flex",
                    marginLeft: "20px",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      value={this.state.userName}
                      label="user Name"
                      disabled={true}
                      style={{ width: "100%" }}
                    />
                    <TextField
                      id="standard-basic"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      label="Name"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="Country Code"
                      value={this.state.countryCode}
                      onChange={(e) =>
                        this.setState({ countryCode: e.target.value })
                      }
                      style={{
                        width: "20%",
                        marginRight: "5px",
                      }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Mobile Number"
                      value={this.state.mobileNumber}
                      onChange={(e) =>
                        this.setState({ mobileNumber: e.target.value })
                      }
                      style={{ width: "80%", marginLeft: "5px" }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="delivery-boi__actions"
                style={{ marginTop: "20px" }}
              >
                {/* <Button variant="outlined" color="primary">
                  Change Password
                </Button> */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => this.props.history.goBack()}
                >
                  Cancel
                </Button>

                <Button
                  variant="outlined"
                  style={{ backgroundColor: "#f37f0a", color: "white" }}
                  onClick={(e) => this.handleUpdate()}
                >
                  Save
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    );
  }
  handleUpdate = () => {
    const { name, countryCode, mobileNumber, _id, partyId } = this.state;
    this.props.update({ name, countryCode, mobileNumber, _id, partyId });
    this.props.history.goBack();
  };
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    update: (data) => dispatch(updateDeliveryBoy(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(editDeliveryBoi)
);
