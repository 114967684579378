import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { TextField, Button, withStyles, Radio } from "@material-ui/core";
const BlueRadio = withStyles({
  root: {
    color: "#555555",
    "&$checked": {
      color: "skyblue",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function App(props) {
  const [value, setValue] = useState(null);
  const [hide, setHide] = useState(false);
  const [discription, setDiscription] = useState("");

  return (
    <Popup
      //   closeOnDocumentClick={hide}
      trigger={
        <button
          style={{
            opacity: 0,
            /* width: 100%; */
            position: "absolute",
            zIndex: 999,
            width: "100%",
            height: "50px",
          }}
        >
          {" "}
          Trigger
        </button>
      }
      modal
      position="right center"
    >
      {(close) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "100px",
              paddingBottom: "100px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <p style={{ color: "skyblue", fontSize: 30 }}>Reject Reason</p>

              <div
                onClick={(e) => setValue("DELIVERY_BOY_NOT_AVAILABLE")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
                <h3
                  style={{
                    color:
                      value === "DELIVERY_BOY_NOT_AVAILABLE"
                        ? "skyblue"
                        : "#555555",
                    fontSize: 20,
                  }}
                >
                  Delivery Boy Not Available
                </h3>
                <BlueRadio
                  checked={value === "DELIVERY_BOY_NOT_AVAILABLE"}
                  onChange={(e) => setValue("DELIVERY_BOY_NOT_AVAILABLE")}
                  value="WRONG_ADDRESS"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <div
                onClick={(e) => setValue("NON_SERVICEABLE_AREA")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
                <h3
                  style={{
                    color:
                      value === "NON_SERVICEABLE_AREA" ? "skyblue" : "#555555",
                    fontSize: 20,
                  }}
                >
                  Non Serviceable Area
                </h3>
                <BlueRadio
                  checked={value === "NON_SERVICEABLE_AREA"}
                  onChange={(e) => setValue("NON_SERVICEABLE_AREA")}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <div
                onClick={(e) => setValue("PRODUCT_NOT_AVAILABLE")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
                <h3
                  style={{
                    color:
                      value === "PRODUCT_NOT_AVAILABLE" ? "skyblue" : "#555555",
                    fontSize: 20,
                  }}
                >
                  Product Not Available
                </h3>
                <BlueRadio
                  checked={value === "PRODUCT_NOT_AVAILABLE"}
                  onChange={(e) => setValue("PRODUCT_NOT_AVAILABLE")}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>

              <div
                onClick={(e) => setValue("PRICE_CORRECTION_REQUIRED")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
                <h3
                  style={{
                    color:
                      value === "PRICE_CORRECTION_REQUIRED"
                        ? "skyblue"
                        : "#555555",
                    fontSize: 20,
                  }}
                >
                  Price Correction Required
                </h3>
                <BlueRadio
                  checked={value === "PRICE_CORRECTION_REQUIRED"}
                  onChange={(e) => setValue("PRICE_CORRECTION_REQUIRED")}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>

              <div
                onClick={(e) => setValue("TIME_SLOT_NOT_POSSIBLE")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
                <h3
                  style={{
                    color:
                      value === "TIME_SLOT_NOT_POSSIBLE"
                        ? "skyblue"
                        : "#555555",
                    fontSize: 20,
                  }}
                >
                  Time Slot Not Possible
                </h3>
                <BlueRadio
                  checked={value === "TIME_SLOT_NOT_POSSIBLE"}
                  onChange={(e) => setValue("TIME_SLOT_NOT_POSSIBLE")}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>

              <div
                onClick={(e) => setValue("OTHER")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
                <h3
                  style={{
                    color: value === "OTHER" ? "skyblue" : "#555555",
                    fontSize: 20,
                  }}
                >
                  Other
                </h3>
                <BlueRadio
                  checked={value === "OTHER"}
                  onChange={(e) => setValue("OTHER")}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              {value === "OTHER" && (
                <TextField
                  id="standard-basic"
                  onChange={(e) => setDiscription(e.target.value)}
                  style={{ width: "80%", display: "flex", flex: 1 }}
                  label="Reason"
                />
              )}
              <Button
                onClick={(e) => {
                  props.onClose(value, discription);
                  close();
                }}
                style={{
                  backgroundColor: "skyblue",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Confirm Reject
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default App;
