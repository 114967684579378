import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Container} from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import XLSX from "xlsx";
import {saveAs} from "file-saver";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
    getOrders2,
    getAllocated2,
    setParty,
    getDelivered2,
    outForDelivery,
    allocatedToDeliveryBoys
} from "../../store/actions/authActions";
import {connect} from "react-redux";

class Orders extends Component {
    componentDidMount = async () => {
        this.props.setScreen("Orders");
        let newNow = moment();
        var start = newNow.startOf("day").toDate();
        var end = newNow.endOf("day").toDate();
        const date = new Date();
        const offset = date.getTimezoneOffset();
        let acceptedToday = await this.props.getOrders({
            start: start,
            end: end,
            offset: offset,
        });
        let allocatedToday = await this.props.getAllocated({
            start: start,
            end: end,
            offset: offset,
        });
        let delivered = await this.props.getDelivered({
            start: start,
            end: end,
            offset: offset,
        });
        let outForDelivery = await this.props.outForDelivery({
            start,
            end,
            offset,
        });
        let allocatedToDelivery = await this.props.allocatedToDeliveryBoys({
            start,
            end,
            offset,
        });
        let newStart = newNow.startOf("day").add(1, "days").toDate();
        var newEnd = newNow.endOf("day").toDate();
        let acceptedTomorrow = await this.props.getOrders({
            start: newStart,
            end: newEnd,
            offset: offset,
        });
        let allocatedTomorrow = await this.props.getAllocated({
            start: newStart,
            end: newEnd,
            offset: offset,
        });
        let newNewStart = newNow.startOf("day").add(1, "days").toDate();
        let acceptedFuture = await this.props.getOrders({
            start: newNewStart,
            offset: offset,
        });
        let allocatedFuture = await this.props.getAllocated({
            start: newNewStart,
            offset: offset,
        });

        this.setState({
            acceptedToday: acceptedToday.length,
            acceptedTomorrow: acceptedTomorrow.length,
            acceptedFuture: acceptedFuture.length,
            allocatedToday: allocatedToday.length,
            allocatedTomorrow: allocatedTomorrow.length,
            allocatedFuture: allocatedFuture.length,
            delivered: delivered.length,
            outForDelivery: outForDelivery.length,
            allocatedToDelivery:allocatedToDelivery.length,
        });
    };

    getArrayCopy = (carts, ids) => {
        let parentArray = [];
        for (let i = 0; i < carts.length; i++) {
            let cart = carts[i];
            let obj = [];
            obj.push(cart[0].order._id);
            obj.push(cart?.[0].shippingType);
            obj.push(
                moment(cart?.[0].shippingDate).utc(true).format("Y-MM-DD")
            );
            obj.push(cart?.[0].shippingTime);
            obj.push(moment(cart.createdDate).utc(true).format("Y-MM-DD"));
            let amount = 0;
            let productIds = "";
            let productDescription = "";

            let shipping = 0;
            for (let j = 0; j < cart.length; j++) {
                let timeCart=cart[j];
                if (shipping < timeCart.shippingPrice) {
                    shipping = timeCart.shippingPrice;
                }
                amount = amount + timeCart.amount * timeCart.quantity;
                productIds = productIds + timeCart.products[0]._id + ",";
                productDescription =
                    productDescription + timeCart.products[0].description;
                if (timeCart.addons.length>0) {
                    for (let k = 0; k<timeCart.addons.length; k++) {
                        let addons = timeCart.addons;
                        debugger;
                        amount =
                            amount +
                            addons[k].amount * addons[k].quantity;
                        productIds =
                            productIds + addons[k].products[0]._id + ",";
                        productDescription =
                            productDescription +
                            addons[k].products[0].description;
                    }
                }
            }
            amount = amount + shipping;
            obj.push(productIds);
            obj.push(productDescription);
            obj.push("");
            obj.push(cart?.[0].address.name);
            obj.push(cart?.[0].address.recipientAddress);
            obj.push(cart?.[0].address.recipientCity);
            obj.push(cart?.[0].address.phone);
            obj.push(cart?.[0]?.order?.senderName);
            obj.push(amount);
            parentArray.push(obj);
        }
        return parentArray;
    }

    renderOrders = (orders) => {
        // let orders = this.state.orders;
        let lastIndex = orders.length - 1;
        let parentArray = [];
        orders &&
        orders.map((order, index) => {
            let ORDER = order;
            let count = 1;
            //let amount = order?.amount;
            let id = order?._id;

            let list = [];
            let carts = JSON.parse(JSON.stringify(order.Cart));
            let arrayDates = [];
            for (let i = 0; i < carts.length; i++) {
                let filetered = arrayDates.filter(
                    (date) => date === carts[i].shippingDate
                );
                if (filetered.length > 0) {
                } else {
                    arrayDates.push(carts[i].shippingDate);
                }
            }
            for (let j = 0; j < arrayDates.length; j++) {
                let dateCarts = carts.filter(
                    (cart) => cart.shippingDate === arrayDates[j]
                );
                let addressArray = [];
                for (let k = 0; k < dateCarts.length; k++) {
                    console.log(dateCarts[k].deliveryAddress._id);
                    let addressCarts = addressArray.filter(
                        (address) => address === dateCarts[k].deliveryAddress._id
                    );
                    if (addressCarts.length > 0) {
                    } else {
                        addressArray.push(dateCarts[k].deliveryAddress._id);
                    }
                }
                for (let m = 0; m < addressArray.length; m++) {
                    let addressCarts = dateCarts.filter(
                        (dateCart) => dateCart.deliveryAddress._id === addressArray[m]
                    );
                    let timeSlotsArray = [];
                    for (let n = 0; n < addressCarts.length; n++) {
                        let timeCarts = timeSlotsArray.filter(
                            (timeSlot) => timeSlot === addressCarts[n].shippingTime
                        );
                        if (timeCarts.length > 0) {
                        } else {
                            timeSlotsArray.push(addressCarts[n].shippingTime);
                        }
                    }

                    for (let l = 0; l < timeSlotsArray.length; l++) {
                        let timeCarts = addressCarts.filter(
                            (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
                        );

                        //       "orderId",
                        // "Delivery Mode",
                        // "Date of Delivery",
                        // "Delivery Slot",
                        // "order Date",
                        // "ProductIds",
                        // "Product Description",
                        // "Gift Message",
                        // "Recipient Name",
                        // "CommonAddressLine",
                        // "City",
                        // "RecipientMobile",
                        // "Sender Name",
                        // "Price"
                        let obj = [];
                        obj.push(ORDER._id);
                        obj.push(timeCarts?.[0].shippingType);
                        obj.push(
                            moment(timeCarts?.[0].shippingDate).utc(true).format("Y-MM-DD")
                        );
                        obj.push(timeCarts?.[0].shippingTime);
                        obj.push(moment(ORDER.createdDate).utc(true).format("Y-MM-DD"));
                        let amount = 0;
                        let productIds = "";
                        let productDescription = "";
                        for (let r = 0; r < timeCarts.length; r++) {
                            amount = amount + timeCarts[r].shippingPrice;
                            amount = amount + timeCarts[r].amount * timeCarts[r].quantity;
                            productIds = productIds + timeCarts[r].products[0]._id + ",";
                            productDescription =
                                productDescription + timeCarts[r].products[0].description;
                            if (timeCarts[r].addons.length > 0) {
                                for (let s = 0; s < timeCarts[r].addons.length; s++) {
                                    amount = amount + timeCarts[r].addons[s].shippingPrice;
                                    amount =
                                        amount +
                                        timeCarts[r].addons[s].amount *
                                        timeCarts[r].addons[s].quantity;
                                    productIds =
                                        productIds + timeCarts[r].addons[s].products[0]._id + ",";
                                    productDescription =
                                        productDescription +
                                        timeCarts[r].addons[s].products[0].description;
                                }
                            }
                        }

                        obj.push(productIds);
                        obj.push(productDescription);
                        obj.push("");
                        obj.push(timeCarts?.[0].deliveryAddress.name);
                        obj.push(timeCarts?.[0].deliveryAddress.recipientAddress);
                        obj.push(timeCarts?.[0].deliveryAddress.recipientCity);
                        obj.push(timeCarts?.[0].deliveryAddress.phone);
                        obj.push(ORDER.senderName);
                        obj.push(amount);
                        parentArray.push(obj);
                    }
                }
            }
        });
        return parentArray;
    };
    makeXlsx = async (type) => {
        let newNow = moment();
        var start = newNow.startOf("day").toDate();
        const date = new Date();
        const offset = date.getTimezoneOffset();
        let parentArray = [];
        if (type === "Allocated") {
            let allocatedFuture = await this.props.getAllocated({
                start: start,
                offset: offset,
            });
            let firstRow = [
                "orderId",
                "Delivery Mode",
                "Date of Delivery",
                "Delivery Slot",
                "order Date",
                "ProductIds",
                "Product Description",
                "Gift Message",
                "Recipient Name",
                "CommonAddressLine",
                "City",
                "RecipientMobile",
                "Sender Name",
                "Price",
            ];
            parentArray.push(firstRow);
            let data = this.getArrayCopy(allocatedFuture);
            parentArray = parentArray.concat(data);
            let dateString = date.toLocaleDateString();
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "SheetJS Tutorial",
                Subject: "Test",
                Author: "Red Stapler",
                CreatedDate: new Date(2017, 12, 19),
            };
            wb.SheetNames.push("Allocated Supplier");
            var ws = XLSX.utils.aoa_to_sheet(parentArray, {header: 1});
            wb.Sheets["Allocated Supplier"] = ws;

            var wbout = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
            saveAs(
                new Blob([this.s2ab(wbout)], {type: "application/octet-stream"}),
                "Allocated Supplier-" + dateString + ".xlsx"
            );
        }
        if (type === "Accepted") {
            let acceptedFuture = await this.props.getOrders({
                start: start,
                offset: offset,
            });
            let firstRow = [
                "orderId",
                "Delivery Mode",
                "Date of Delivery",
                "Delivery Slot",
                "order Date",
                "ProductIds",
                "Product Description",
                "Gift Message",
                "Recipient Name",
                "CommonAddressLine",
                "City",
                "RecipientMobile",
                "Sender Name",
                "Price",
            ];

            parentArray.push(firstRow);
            debugger;
            let data = this.getArrayCopy(acceptedFuture);
            parentArray = parentArray.concat(data);
            let dateString = date.toLocaleDateString();
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "SheetJS Tutorial",
                Subject: "Test",
                Author: "Red Stapler",
                CreatedDate: new Date(2017, 12, 19),
            };

            wb.SheetNames.push("Accepted Supplier");
            var ws = XLSX.utils.aoa_to_sheet(parentArray, {header: 1});
            wb.Sheets["Accepted Supplier"] = ws;

            var wbout = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
            saveAs(
                new Blob([this.s2ab(wbout)], {type: "application/octet-stream"}),
                "Accepted Supplier-" + dateString + ".xlsx"
            );
        }
        if (type === "Out") {
            let outForDelivery = await this.props.outForDelivery({
                start: start,
                offset: offset,
            });
            let firstRow = [
                "orderId",
                "Delivery Mode",
                "Date of Delivery",
                "Delivery Slot",
                "order Date",
                "ProductIds",
                "Product Description",
                "Gift Message",
                "Recipient Name",
                "CommonAddressLine",
                "City",
                "RecipientMobile",
                "Sender Name",
                "Price",
            ];
            parentArray.push(firstRow);
            let data = this.renderOrders(outForDelivery);
            parentArray = parentArray.concat(data);
            let dateString = date.toLocaleDateString();
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "SheetJS Tutorial",
                Subject: "Test",
                Author: "Red Stapler",
                CreatedDate: new Date(2017, 12, 19),
            };
            wb.SheetNames.push("Out_for_delivery");
            var ws = XLSX.utils.aoa_to_sheet(parentArray, {header: 1});
            wb.Sheets["Out_for_delivery"] = ws;

            var wbout = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
            saveAs(
                new Blob([this.s2ab(wbout)], {type: "application/octet-stream"}),
                "Out_for_delivery-" + dateString + ".xlsx"
            );
        }
        if (type === "drivers") {
            let outForDelivery = await this.props.allocatedToDeliveryBoys({
                start: start,
                offset: offset,
            });
            let firstRow = [
                "orderId",
                "Delivery Mode",
                "Date of Delivery",
                "Delivery Slot",
                "order Date",
                "ProductIds",
                "Product Description",
                "Gift Message",
                "Recipient Name",
                "CommonAddressLine",
                "City",
                "RecipientMobile",
                "Sender Name",
                "Price",
            ];
            parentArray.push(firstRow);
            let data = this.renderOrders(outForDelivery);
            parentArray = parentArray.concat(data);
            let dateString = date.toLocaleDateString();
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "SheetJS Tutorial",
                Subject: "Test",
                Author: "Red Stapler",
                CreatedDate: new Date(2017, 12, 19),
            };
            wb.SheetNames.push("Out_for_delivery");
            var ws = XLSX.utils.aoa_to_sheet(parentArray, {header: 1});
            wb.Sheets["Out_for_delivery"] = ws;

            var wbout = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
            saveAs(
                new Blob([this.s2ab(wbout)], {type: "application/octet-stream"}),
                "Allocated To Drivers-" + dateString + ".xlsx"
            );
        }
    };

    s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
    }

    state = {
        allocatedToday: "",
        allocatedTomorrow: "",
        allocatedFuture: "",
        acceptedToday: "",
        acceptedTomorrow: "",
        acceptedFuture: "",
        delivered: "",
        outForDelivery: "",
        allocatedToDelivery:'',
    };

    render() {
        return (
            <Container>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "15px",
                            flexDirection: "row",
                            height: "50px",
                            border: "2px solid",
                            borderColor: "#d9d7d7",
                            borderRadius: 5,
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                borderRight: "1px solid #e7e9ea",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingRight: "5px",
                                paddingLeft: "5px",
                                flexDirection: "row",
                            }}
                        >
                            <p style={{fontSize: 20}}>Allocated</p>
                            <img
                                onClick={() => this.makeXlsx("Allocated")}
                                src={img}
                                alt=""
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    width: "40px",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                backgroundColor: "#ccffce",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                                justifyContent: "center",
                                borderRight: "1px solid #e7e9ea",
                            }}
                            onClick={
                                (e) => {
                                    let now = moment();
                                    var start = now.startOf("day").toDate();
                                    var end = now.endOf("day").toDate();
                                    const date = new Date();
                                    const offset = date.getTimezoneOffset();
                                    this.props.history.push({
                                        pathname: "/ListOrders",
                                        state: {
                                            type: "Allocated",
                                            day: "Today",

                                            start: start,
                                            end: end,
                                            offset,
                                        },
                                    });
                                }
                                //do Something
                            }
                        >
                            <p>TODAY</p>
                            <p>({this.state.allocatedToday})</p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#fdf2c7",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                                justifyContent: "center",
                                borderRight: "1px solid #e7e9ea",
                            }}
                            onClick={(e) => {
                                let now = moment();
                                var start = now.startOf("day").add(1, "days").toDate();
                                var end = now.endOf("day").toDate();
                                const date = new Date();
                                const offset = date.getTimezoneOffset();

                                this.props.history.push({
                                    pathname: "/ListOrders",
                                    state: {
                                        type: "Allocated",
                                        day: "Tomorrow",

                                        start: start,
                                        end: end,
                                        offset,
                                    },
                                });
                            }}
                        >
                            <p>TOMORROW</p>
                            <p>({this.state.allocatedTomorrow})</p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#fee8e8",
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                cursor: "pointer",
                                justifyContent: "center",
                                borderRight: "1px solid #e7e9ea",
                            }}
                            onClick={(e) => {
                                let now = moment();
                                var start = now.startOf("day").add(2, "days").toDate();
                                const date = new Date();
                                const offset = date.getTimezoneOffset();
                                this.props.history.push({
                                    pathname: "/ListOrders",
                                    state: {
                                        type: "Allocated",
                                        day: "Future",

                                        start: start,
                                        offset,
                                    },
                                });
                            }}
                        >
                            <p>FUTURE</p>
                            <p>({this.state.allocatedFuture})</p>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "15px",
                            flexDirection: "row",
                            height: "50px",
                            border: "2px solid",
                            borderColor: "#d9d7d7",
                            borderRadius: 5,
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                borderRight: "1px solid #e7e9ea",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingRight: "5px",
                                paddingLeft: "5px",
                                flexDirection: "row",
                            }}
                            onClick={(e) =>
                                //do Something
                                console.log("something")
                            }
                        >
                            <p style={{fontSize: 20}}>Accepted</p>
                            <img
                                onClick={() => this.makeXlsx("Accepted")}
                                src={img}
                                alt=""
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    width: "40px",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                backgroundColor: "#ccffce",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                borderRight: "1px solid #e7e9ea",
                            }}
                            onClick={(e) => {
                                let now = moment();
                                var start = now.startOf("day").toDate();
                                var end = now.endOf("day").toDate();
                                const date = new Date();
                                const offset = date.getTimezoneOffset();
                                this.props.history.push({
                                    pathname: "/ListOrders",
                                    state: {
                                        type: "Accepted",
                                        day: "Today",

                                        start: start,
                                        end: end,
                                        offset,
                                    },
                                });
                            }}
                        >
                            <p>TODAY</p>
                            <p>({this.state.acceptedToday})</p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#fdf2c7",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                                justifyContent: "center",
                                borderRight: "1px solid #e7e9ea",
                            }}
                            onClick={(e) => {
                                let now = moment();
                                var start = now.startOf("day").add(1, "days").toDate();
                                var end = now.endOf("day").toDate();
                                const date = new Date();
                                const offset = date.getTimezoneOffset();
                                this.props.history.push({
                                    pathname: "/ListOrders",
                                    state: {
                                        type: "Accepted",
                                        day: "Tomorrow",
                                        start: start,
                                        end: end,
                                        offset,
                                    },
                                });
                            }}
                        >
                            <p>TOMORROW</p>
                            <p>({this.state.acceptedTomorrow})</p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#fee8e8",
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                cursor: "pointer",
                                justifyContent: "center",
                                borderRight: "1px solid #e7e9ea",
                            }}
                            onClick={(e) => {
                                let now = moment();
                                var start = now.startOf("day").add(2, "days").toDate();
                                const date = new Date();
                                const offset = date.getTimezoneOffset();
                                this.props.history.push({
                                    pathname: "/ListOrders",
                                    state: {
                                        type: "Accepted",
                                        day: "Future",
                                        start: start,
                                        offset,
                                    },
                                });
                            }}
                        >
                            <p>FUTURE</p>
                            <p>({this.state.acceptedFuture})</p>
                        </div>
                    </div>
                    <div
                        onClick={(e) =>
                            this.props.history.push({
                                pathname: "/delivery-boys",
                            })
                        }
                        style={{
                            display: "flex",
                            marginTop: "15px",
                            flexDirection: "row",
                            height: "50px",
                            border: "2px solid",
                            cursor: "pointer",
                            borderColor: "#d9d7d7",
                            borderRadius: 5,
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <p style={{fontSize: 20}}>Out for delivery</p>
                            <img
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.makeXlsx("Out");
                                }}
                                src={img}
                                alt=""
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    width: "40px",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <p style={{fontSize: 20}}>({this.state.outForDelivery})</p>
                            <NavigateNextIcon/>
                        </div>
                    </div>

                    <div
                        onClick={(e) =>
                            this.props.history.push({
                                pathname: "/drivers",
                            })
                        }
                        style={{
                            display: "flex",
                            marginTop: "15px",
                            flexDirection: "row",
                            height: "50px",
                            border: "2px solid",
                            cursor: "pointer",
                            borderColor: "#d9d7d7",
                            borderRadius: 5,
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <p style={{fontSize: 20}}>Allocated To Drivers</p>
                            <img
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.makeXlsx("drivers");
                                }}
                                src={img}
                                alt=""
                                style={{
                                    display: "flex",
                                    height: "30px",
                                    width: "40px",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <p style={{fontSize: 20}}>({this.state.allocatedToDelivery})</p>
                            <NavigateNextIcon/>
                        </div>
                    </div>


                    <div
                        style={{
                            display: "flex",
                            marginTop: "15px",
                            flexDirection: "row",
                            height: "50px",
                            border: "2px solid",
                            cursor: "pointer",
                            borderColor: "#d9d7d7",
                            borderRadius: 5,
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                        }}
                        onClick={(e) => {
                            let now = moment();
                            var start = now.startOf("day").toDate();
                            var end = now.endOf("day").toDate();
                            const date = new Date();
                            const offset = date.getTimezoneOffset();
                            this.props.history.push({
                                pathname: "/ListOrders",
                                state: {
                                    type: "Delivered",
                                    day: "Today",
                                    start: start,
                                    end: end,
                                    offset,
                                },
                            });
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                //alignItems: "center",
                                flexDirection: "column",

                                justifyContent: "center",
                            }}
                        >
                            <p style={{fontSize: 20}}>Delivered/Attempted</p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                            }}
                        ></div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                            onClick={(e) =>
                                //do Something
                                console.log("something")
                            }
                        >
                            <p style={{fontSize: 20}}>({this.state.delivered})</p>
                            <NavigateNextIcon/>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }

    handleClick = () => {
    };
}

const mapStateToProps = (state) => {
    return {
        orders: state.auth.orders,
    };
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        getOrders: (data) => dispatch(getOrders2(data, router)),
        getAllocated: (data) => dispatch(getAllocated2(data, router)),
        setParty: (data) => dispatch(setParty(data, router)),
        getDelivered: (data) => dispatch(getDelivered2(data, router)),
        outForDelivery: (data) => dispatch(outForDelivery(data)),
        setScreen: (data) => dispatch({type: "SCREEN", payload: data}),
        allocatedToDeliveryBoys:(data)=>dispatch(allocatedToDeliveryBoys(data)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
