import React, { Component } from "react";
import flowers from "../../assets/images/flowers.jpg";

class row extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
          }}
        >
          <img
            src={this.props.image ? this.props.image : ""}
            alt=""
            style={{ height: 120, width: 110 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <p style={{ fontSize: 20 }}>
              {this.props.name} [{this.props.id}]
            </p>
            <p>{this.props?.description}</p>
            <p style={{ marginTop: "30px", fontSize: 20 }}>
              AED. {this.props.amount}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: "20px",
            alignItems: "center",
          }}
        >
          <p>QTY</p>
          <p
            style={{
              display: "flex",
              height: "20px",
              width: "20px",
              borderRadius: "10px",
              backgroundColor: "red",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.props.quantity}
          </p>
        </div>
      </div>
    );
  }
}

export default row;
