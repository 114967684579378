import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

const persistConfig = {
  key: "auth",
  storage,
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
    rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

// export let persistor = persistStore(store);
