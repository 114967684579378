const initialState = {
  completedOrders: [],
  password: false,
  token: null,
  user: {},
  submitted: false,
  orders: [],
  deliveryBoys: [],
  deliveryBoyId: null,
  partyId: null,
  screen: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_USER":
      var payload = action.payload;
      var token = payload.token;
      var deliveryBoyId = payload.deliveryBoyId;
      var partyId = payload.partyId;
      var data = {
        ...state,
        user: payload,
        token: token,
        deliveryBoyId: deliveryBoyId,
        partyId: partyId,
      };
      return data;
    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("deliveryBoyId");
      localStorage.removeItem("partyId");
      var final = {
        ...state,
        user: {},
        token: null,
        deliveryBoyId: null,
        partyId: null,
      };
      return final;
    case "SUBMITTED":
      return { ...state, submitted: action.payload };
    case "ORDERS":
      return { ...state, orders: action.payload };
    case "DELIVERY_BOYS":
      return { ...state, deliveryBoys: action.payload };
    case "PASSWORD":
      return { ...state, password: action.payload };
    case "COMPLETED":
      return { ...state, completedOrders: action.payload };
    case "ADD_DELIVERY_BOYS":
      return {
        ...state,
        deliveryBoys: [...state.deliveryBoys, action.payload],
      };
    case "SCREEN":
      return { ...state, screen: action.payload };
    default:
      return state;
  }
};

export default authReducer;
