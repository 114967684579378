import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Button } from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import flowers from "../../assets/images/flowers.jpg";
import CartComponent from "./cartComponent";
import { connect } from "react-redux";
import PrintIcon from "@material-ui/icons/Print";
import {
  setParty,
  rejectOrder,
  getImage,
  temp,
} from "../../store/actions/authActions";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import imag from "../../assets/images/siteLogo.jpg";
import imag2 from "../../assets/images/Beautiful Bunch Baby Roses.webp";
import Popup from "./temp";
import JsBarcode from "jsbarcode";
const { createCanvas, loadImage } = require("canvas");

// playground requires you to assign document definition to a variable called dd

// playground requires you to assign document definition to a variable called dd

var message = [
  {
    fillColor: "#f0f8ff",

    columns: [
      { text: "" },
      {
        margin: [20, 20],
        table: {
          heights: [20, 150, 10, 20],
          body: [
            ["barney"],
            [
              "\nWishing you a speedy and full recovery <3 Hope you feel much better very soon. P.S it's super boring without you!",
            ],
            ["From"],
            ["ABC"],
          ],
        },
        layout: "noBorders",
      },
    ],
  },
];
var watermark = {
  watermark: { text: 'COD', color: 'red', opacity: 0.3, bold: true, italics: false },
}
var Amount = [
  { text: "Amount: ", bold: true },
  { text: "100",
    bold:true,
    color:'#FF0000',
    fontSize:20},
]
var logoRow = [
  {
    columns: [
      {
        margin: [20, 20],
        image: "sampleImage.jpg",
        width: 100,
        height: 40,
      },
      { text: "" },
      {
        margin: [0, 20, 20, 20],
        image: "sampleImage.jpg",
        width: 100,
        height: 40,
      },
    ],
  },
];
var addressRow = [
  {
    columns: [
      {
        margin: [30, 20, 20, 20],
        text: [
          { text: "Deliver To", bold: true },
          { text: "\nTo: " },
          { text: "Barny Shaw" },
          { text: "\nApartment 102, 55 al ramith," },
          { text: "\nremraam Dubai uae" },
          { text: "\nDubai" },
          { text: "\n03075861606" },
        ],
      },
      {
        margin: [50, 20, 0, 20],
        text: [
          { text: "\nOrder#: ", bold: true },
          { text: "66287101" },
          { text: "\nOrder Date: ", bold: true },
          { text: "25-11-2020" },
          { text: "\nDelivery Date: ", bold: true },
          { text: "25-11-2020" },
          { text: "\nShipping: ", bold: true },
          { text: "Standard_Delivery" },
          { text: "\nTime: ", bold: true },
          { text: "15:00:00 - 19:00:00" },

        ],
      },
    ],
  },
];
var productRow = [
  {
    table: {
      widths: [120, 100, 150, "*", 50],
      body: [
        [
          { text: "Product Image", bold: true },
          { text: "Product", bold: true },
          { text: "Product Description", bold: true },
          { text: "Uploaded Image", bold: true },
          { text: "Quantity", bold: true },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 1 : 1;
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0 : 1;
      },
      hLineColor: function (i, node) {
        return i === 0 || i === node.table.body.length ? "black" : "black";
      },
      vLineColor: function (i, node) {
        return i === 0 || i === node.table.widths.length ? "black" : "black";
      },
      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
      // paddingLeft: function(i, node) { return 4; },
      // paddingRight: function(i, node) { return 4; },
      // paddingTop: function(i, node) { return 2; },
      // paddingBottom: function(i, node) { return 2; },
      // fillColor: function (rowIndex, node, columnIndex) { return null; }
    },
  },
];
var senderRow = [
  {
    margin: [30, 20],
    text: [{ text: "Sender: " }, { text: "Nagla" }],
  },
];

var cc = {
  pageMargins: [0, 0, 0, 0],
  content: [
    {
      table: {
        // heights: [250],
        widths:['*'],
        body: [],
      },
      layout: {
        hLineWidth: function (i, node) {
          //	return (i !== 1 || i===2   ) ? 0 : 1;
          return i === 1 || i === 2 ? 1 : 0;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0 : 1;
        },
        hLineColor: function (i, node) {
          return "no";
        },
        vLineColor: function (i, node) {
          return "black";
        },
        hLineStyle: function (i, node) {
          if (i !== 1) {
            return null;
          }
          return { dash: { length: 5, space: 4 } };
        },
        vLineStyle: function (i, node) {
          if (i === 0 || i === node.table.widths.length) {
            return null;
          }
          return { dash: { length: 4 } };
        },
        // paddingLeft: function(i, node) { return 4; },
        // paddingRight: function(i, node) { return 4; },
        // paddingTop: function(i, node) { return 2; },
        // paddingBottom: function(i, node) { return 2; },
        // fillColor: function (i, node) { return null; }
      },
    },
  ],
  styles: {
    header: {
      fillColor: "gray",
    },
  },
};

var external = {
  hLineWidth: function (i, node) {
    //	return (i !== 1 || i===2   ) ? 0 : 1;
    return i === 1 || i === 2 ? 1 : 0;
  },
  vLineWidth: function (i, node) {
    return i === 0 || i === node.table.widths.length ? 0 : 1;
  },
  hLineColor: function (i, node) {
    return "no";
  },
  vLineColor: function (i, node) {
    return "black";
  },
  hLineStyle: function (i, node) {
    // if (i !== 1) {
    //   return null;
    // }
    // return { dash: { length: 5, space: 4 } };
  },
  vLineStyle: function (i, node) {
    if (i === 0 || i === node.table.widths.length) {
      return null;
    }
    return { dash: { length: 4 } };
  },
};

var internal = {
  hLineWidth: function (i, node) {
    return i === 0 || i === node.table.body.length ? 1 : 0;
  },
  vLineWidth: function (i, node) {
    return i === 0 || i === node.table.widths.length ? 0 : 1;
  },
  hLineColor: function (i, node) {
    return i === 0 || i === node.table.body.length ? "black" : "black";
  },
  vLineColor: function (i, node) {
    return i === 0 || i === node.table.widths.length ? "black" : "black";
  },
};

//import Popup from "reactjs-popup";
class orderDetails extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }
  quantityObj(quantity) {
    let obj = {};
    obj.margin = [10, 40];
    obj.alignment = "center";
    obj.text = quantity;
    obj.fontSize = 20;
    obj.bold = true;
    obj.color='#FF0000'
    return obj;
  }
  async imageObj(url) {
    let obj = {};
    obj.width = 100;
    obj.height = 60;
    obj.margin = [10, 20];
    if (url) {
      let d = await this.convertUrl(url);
      obj.image = d;
      return obj;
    } else {
      obj.text = "";
      return obj;
    }
  }
  description(des) {
    let obj = {};
    obj.margin = [0, 20];
    obj.fontSize = 10;
    if (des) {
      obj.text = des;
      return obj;
    } else {
      obj.text = "";
      return obj;
    }
  }
  productName(name) {
    let obj = {};
    obj.margin = [0, 20];
    obj.fontSize = 10;
    if (name) {
      obj.text = name;
      return obj;
    } else {
      obj.text = "";
      return obj;
    }
  }
  async productImg(url) {
    let obj = {};
    obj.width = 120;
    obj.height = 100;
    // obj.margin = [10, 20];
    if (url) {
      let d = await this.convertUrl(url);
      obj.image = d;
      return obj;
    } else {
      obj.text = "";
      return obj;
    }
  }
  async makeRow(productUrl, name, desc, imageUrl, quantity) {
    let array = [];
    let productObj = await this.productImg(productUrl);
    debugger;
    let productName = this.productName(name);
    let des = this.description(desc);
    let imageObj = await this.imageObj(imageUrl);
    let qty = this.quantityObj(quantity);
    array.push(productObj);
    array.push(productName);
    array.push(des);
    array.push(imageObj);
    array.push(qty);
    return array;
  }
  onClose = (reason, discription) => {
    this.reject(reason, discription);
  };
  state = {
    trigger: false,
    dataUrl: "",
  };
  accept = () => {
    let carts = this.props.location.state.carts;
    let subOrderIds = [];
    carts.map((cart) => {
      subOrderIds.push(cart.subOrderId);
    });

    this.props.setParty({
      subOrderIds,
      acceptedDate: new Date(),
      start: this.props.location.state.start,
      end: this.props.location.state.end,
      offset: "",
    });
    this.props.history.goBack();
  };
  reject = (reason, discription) => {
    let carts = this.props.location.state.carts;
    let cartIds = carts.map((cart) => {
      return cart._id;
    });
    this.props.rejectOrder({
      cartIds: cartIds,
      start: this.props.location.state.start,
      end: this.props.location.state.end,
      reason: reason,
      otherDescription: discription,
      offset: "",
    });
    this.props.history.goBack();
  };
  printChallan = async () => {
    let dd = JSON.parse(JSON.stringify(cc));
    // let data1 = await this.convert(imag2);
    // console.log(data1);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let canvas = createCanvas(500, 500);
    console.log(this.props.location.state.orderId);
    JsBarcode(canvas, this.props.location.state.orderId);
    dd.content[0].layout = external;
    let arrayOfObj = [];

    for (let k = 0; k < this.props.location.state.carts.length; k++) {
      let cart = this.props.location.state.carts[k];
      if (cart.giftCard) {
        let obj = {};
        obj.giftCard_message = cart.giftCard_message;
        obj.giftCard_occasion = cart.giftCard_occasion;
        obj.giftCard_receiver = cart.giftCard_receiver;
        obj.giftCard_sender = cart.giftCard_sender;
        arrayOfObj.push(obj);
      }
    }
    if (arrayOfObj.length < 1) {
      let obj = {};
      obj.giftCard_message = "";
      obj.giftCard_occasion = "";
      obj.giftCard_receiver = "";
      obj.giftCard_sender = "";
      arrayOfObj.push(obj);
    }
    let mRow = {};

    for (let m = 0; m < arrayOfObj.length; m++) {
      let mRow = JSON.parse(JSON.stringify(message));
      mRow[0].columns[1].table.body[0][0] = arrayOfObj[m].giftCard_receiver;
      mRow[0].columns[1].table.body[1][0] = arrayOfObj[m].giftCard_message;
      mRow[0].columns[1].table.body[3][0] = arrayOfObj[m].giftCard_sender;
      // dd.content[0].table.body.push(mRow);
    }
    let lRow = JSON.parse(JSON.stringify(logoRow));
    let addRow = JSON.parse(JSON.stringify(addressRow));
    let pRow = JSON.parse(JSON.stringify(productRow));
    let sendRow = JSON.parse(JSON.stringify(senderRow));
    let aaaaa = canvas.toDataURL();
    lRow[0].columns[2].image = aaaaa;

    //populating siteLogo
    let data = await this.convert(imag);
    lRow[0].columns[0].image = data;

    //populating orderDetails
    addRow[0].columns[1].text[1].text = this.props.location.state.orderId;
    addRow[0].columns[1].text[3].text = moment(
      this.props.location.state?.orderDate
    )
      .utc(true)
      .format("Y-MM-DD");
    addRow[0].columns[1].text[5].text = moment(
      this.props.location.state?.shippingInfo?.shippingDate
    )
      .utc(true)
      .format("Y-MM-DD");
    debugger;
    addRow[0].columns[1].text[7].text = this.props.location.state?.shippingInfo?.type;
    addRow[0].columns[1].text[9].text = this.props.location.state?.shippingInfo?.time;
    //populating delivery detail
    addRow[0].columns[0].text[2].text = this.props.location.state.deliveryInfo?.name;
    addRow[0].columns[0].text[3].text =
      "\n" + this.props.location.state.deliveryInfo?.recipientAddress;
    addRow[0].columns[0].text[4].text =
      "\n" + this.props.location.state.deliveryInfo?.landmark;
    addRow[0].columns[0].text[5].text =
      "\n" + this.props.location.state.deliveryInfo?.recipientCity;
    addRow[0].columns[0].text[6].text =
      "\n" + this.props.location.state.deliveryInfo?.phone;

    pRow.layout = internal;
    //populating sender
    sendRow[0].text[1].text = this.props.location.state?.senderName
      ? this.props.location.state?.senderName
      : "";
    //populating productInfo
    let amount=0;
    let paidAmount=0;
    let shipping = 0;
    new Promise(async (resolve, reject) => {
      for (let i = 0; i < this.props.location.state.carts.length; i++) {
        let qty = this.props.location.state.carts[i].quantity;
        let pImg = this.props.location.state.carts[i].products[0].images?.[0];
        let pName = this.props.location.state.carts[i].products[0].title;
        let des = this.props.location.state.carts[i].products[0].description;
        let uImg = this.props.location.state.carts[i].image;
        amount = amount + this.props.location.state.carts[i].amount* this.props.location.state.carts[i].quantity;
        paidAmount = paidAmount+ this.props.location.state.carts[i].paidAmount;
        shipping = this.props.location.state.carts[i].shippingPrice;
        let arr = await this.makeRow(pImg, pName, des, uImg, qty);
        pRow[0].table.body.push(arr);
        if (this.props.location.state.carts[i].addons.length > 0) {
          for (
            let j = 0;
            j < this.props.location.state.carts[i].addons.length;
            j++
          ) {
            let qty = this.props.location.state.carts[i].addons[j].quantity;
            let pImg = this.props.location.state.carts[i].addons[j].products[0]
              .images?.[0];
            let pName = this.props.location.state.carts[i].addons[j].products[0]
              .title;
            let des = this.props.location.state.carts[i].addons[j].products[0]
              .description;
            amount = amount + this.props.location.state.carts[i].addons[j].amount* this.props.location.state.carts[i].addons[j].quantity;
            paidAmount = paidAmount + this.props.location.state.carts[i].addons[j].paidAmount;
            let uImg = null;
            let arr = await this.makeRow(pImg, pName, des, uImg, qty);

            pRow[0].table.body.push(arr);
          }
        }
      }
      if(amount+shipping>paidAmount){
        let AmountRow = JSON.parse(JSON.stringify(Amount));
        AmountRow[1].text = amount+shipping-paidAmount
        addRow[0].columns[1].text.unshift(AmountRow[1]);
        addRow[0].columns[1].text.unshift(AmountRow[0]);
        dd.watermark=watermark.watermark;
      }


      dd.content[0].table.body.push(lRow);
      dd.content[0].table.body.push(addRow);
      dd.content[0].table.body.push(pRow);
      // dd.content[0].table.body.push(sendRow);
      dd.pageMargins = [0, 0, 0, 0];
      console.log(dd);
      pdfMake.createPdf(dd).open();
    });
  };
  convertUrl = async (url) => {
    let res = await this.props.getImage({ url: url });
    let newD = res;
    return newD;
  };
  convert = (image) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.src = image;
      img.onload = function () {
        var canvas = document.createElement("canvas"),
          context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        const data = canvas.toDataURL("image/png");
        resolve(data);
      };
    });
  };
  render() {
    return (
      <Container>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 25,
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderRadius: "5px",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>{this.props.location.state.orderId}</p>
          {this.props.location.state.type === "Accepted" && (
            <PrintIcon
              onClick={() => this.printChallan()}
              style={{
                marginRight: "20px",
                height: "40px",
                width: "40px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        {this.props.location.state.type === "Delivered" && (
          <div>
            <div
              style={{
                backgroundColor: "#7abbe1",
                color: "white",
                fontSize: 20,
                marginTop: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "15px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              Receiver's Details
            </div>
            <div
              style={{
                backgroundColor: "white",
                fontSize: 15,
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "20px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <p>{this.props.location.state.carts[0].receivedBy}</p>
              <p>
                Delivered Time :
                {moment(this.props.location.state.carts[0].deliveryDate)
                  .utc(true)
                  .format("HH:mm")}
              </p>
              <p>
                {moment(this.props.location.state.carts[0].deliveryDate)
                  .utc(true)
                  .format("Y-MM-DD")}
              </p>
              <p>Relation : {this.props.location.state.carts[0].relation}</p>
            </div>
          </div>
        )}
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Delivery Details
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <p>
            Delivery Date :{" "}
            {moment(this.props.location.state?.shippingInfo?.date)
              .utc(true)
              .format("Y-MM-DD")}
          </p>
          <p>Delivery Mode : {this.props.location.state?.shippingInfo?.type}</p>
          <p>Delivery Time : {this.props.location.state?.shippingInfo?.time}</p>
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Recipient Details
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <p>{this.props.location.state.deliveryInfo?.name}</p>
          <p>
            {this.props.location.state.deliveryInfo?.recipientAddress},{" "}
            {this.props.location.state.deliveryInfo?.landmark},{" "}
            {this.props.location.state.deliveryInfo?.recipientCity}
          </p>
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Payment Details
        </div>

        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            flexDirection: "row",
            display: "flex",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <p style={{ fontSize: 20 }}>Payment Method:</p>
          <p
            style={{
              fontSize: 20,
              color:
                this.props.location.state.paymentMethod === "cod"
                  ? "red"
                  : "black",
              fontWeight:
                this.props.location.state.paymentMethod === "cod" ? "bold" : "",
            }}
          >
            {" "}
            {this.props.location.state.paymentMethod}
          </p>
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Delivery Boy capture images
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        ></div>
        {this.props.location.state.carts.map((cart) => (
          <CartComponent cart={cart} />
        ))}

        {this.props.location.state.type === "Allocated" && (
          <div>
            <Button
              style={{
                width: "50%",
                bottom: 0,
                left: 0,
                backgroundColor: "#ef516e",
                color: "white",
                fontSize: 20,
                display: "flex",
                marginTop: "10px",
                position: "fixed",
              }}
              variant="contained"
            >
              REJECT
              <Popup onClose={this.onClose}></Popup>
            </Button>

            <Button
              style={{
                width: "50%",
                bottom: 0,
                right: 0,
                backgroundColor: "#39b97b",
                color: "white",
                fontSize: 20,
                display: "flex",
                marginTop: "10px",
                position: "fixed",
              }}
              onClick={(e) => this.accept()}
              variant="contained"
            >
              ACCEPT
            </Button>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.auth.orders,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    setParty: (data) => dispatch(setParty(data, router)),
    rejectOrder: (data) => dispatch(rejectOrder(data, router)),
    getImage: (data) => dispatch(getImage(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(orderDetails)
);
