import {Alert} from "../components/Alert";

function Notify(type, msg, title = null) {
    title = type === "danger" ? "Error !" : "Success !";
    Alert({
        trigger: true,
        message: msg,
        type: type,
        title: title,
    });
}

function redirect(router, route) {
    router.history.push(route);
}

function goBack(router) {
    router.history.goBack();
}

export const times = [
    {label: "09:00 - 10:00 hrs", val: "1"},
    {label: "10:00 - 11:00 hrs", val: "1"},
    {label: "11:00 - 12:00 hrs", val: "1"},
    {label: "12:00 - 13:00 hrs", val: "1"},
    {label: "13:00 - 14:00 hrs", val: "1"},
    {label: "14:00 - 15:00 hrs", val: "1"},
    {label: "15:00 - 16:00 hrs", val: "1"},
    {label: "16:00 - 17:00 hrs", val: "1"},
    {label: "17:00 - 18:00 hrs", val: "1"},
    {label: "18:00 - 19:00 hrs", val: "1"},
    {label: "19:00 - 20:00 hrs", val: "1"},
];
export const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

function getDateParts(sDate) {
    sDate = new Date(Date.parse(sDate));
    var date = sDate.getUTCDate();
    var day = days?.[sDate.getUTCDay() - 1];
    var month = months?.[sDate.getUTCMonth()];
    var year = sDate.getUTCFullYear();
    return {date, day, month, year};
}

export function dateCase(date) {
    var parts = getDateParts(date);
    return parts?.day + "-" + parts?.month + "-" + parts?.year;
}

export function parseJson(data) {
    if (typeof data === "string" || data instanceof String) {
        return JSON.parse(data);
    } else {
        return data;
    }
}

const titleCase = (title) =>
    title
        .split("-")
        .join(" ")
        .split(/ /g)
        .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
        .join(" ");
export const pathParts = (pathName) => {
    return pathName ? pathName.split("/") : [];
};
export const activeByURI = (part, route, className = "selected") => {
    return part === route ? className : "";
};
export {Notify, redirect, getDateParts, titleCase, goBack};
