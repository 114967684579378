import React, { Component, Fragment } from "react";
import { Container, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import "./style.scss";
import logo from "../../assets/images/siteLogo.jpg";
import { loginNow, setPassword } from "../../store/actions/authActions";
import { identity } from "lodash";

const Header = () => {
  return (
    <header className="login__header">
      <img src={logo} alt="logo" style={{ width: "50px", height: "auto" }} />
    </header>
  );
};

class password extends Component {
  state = {
    password: "",
    confirmPassword: "",
  };

  buttonStyles = {
    marginTop: "30px",
    background: "#2199e8",
    borderRadius: "0px",
    color: "#fff",
  };

  setPass = () => {
    const url = new URLSearchParams(this.props?.location?.search);
    const id = url?.get("id");
    if (this.state.password.trim() !== "") {
      if (this.state.password === this.state.confirmPassword) {
        this.props.set({ password: this.state.password, id: id });
      } else {
        this.setState({ passwordMatch: true });
        setTimeout(async () => {
          this.setState({ passwordMatch: false });
        }, 3000);
      }
    } else {
      this.setState({ passwordEmpty: true });
      setTimeout(async () => {
        this.setState({ passwordEmpty: false });
      }, 3000);
    }
  };

  render() {
    return (
      <div className="login">
        <Header />
        <h1 className="login-h1">Create Password</h1>
        <Container maxWidth="lg">
          <TextField
            type="text"
            // label="Name"
            placeholder="password"
            name="password"
            type="password"
            style={{ width: "100%" }}
            value={this.state.password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />

          <TextField
            type="password"
            // label="Name"
            placeholder="Confirm Password"
            name="confirmPassword"
            type="password"
            style={{ width: "100%", marginTop: "20px" }}
            value={this.state.confirmPassword}
            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
          />
          {this.state.passwordEmpty && (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Password field is empty.
            </p>
          )}
          {this.state.passwordMatch && (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Password doesn't match.
            </p>
          )}

          <Button
            variant="contained"
            style={this.buttonStyles}
            onClick={this.setPass}
          >
            CREATE
          </Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    set: (data) => dispatch(setPassword(data, router)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(password);
