import React from "react";
import { TextField, Button, Container, Radio } from "@material-ui/core";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  getDeliveryBoys,
  updateDeliveryBoy,
  setDeliveryBoy,
  searchDeliveryBoys,
  getPending2,
} from "../../store/actions/authActions";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import "./style.scss";
import avatar from "../../assets/images/avatar.png";

// const useStyles = makeStyles({
//     root: {
//         minWidth: 275,
//     },
//     bullet: {
//         display: 'inline-block',
//         margin: '0 2px',
//         transform: 'scale(0.8)',
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },
// });

class DeliveryBoi extends React.Component {
  // classes = useStyles();
  // bull = <span className={this.classes.bullet}>•</span>;
  componentDidMount() {
    this.props.get();
    if (this.props.location?.state?.subOrderIds) {
      this.setState({ flag: true });
    }
    let newNow = moment();
    var start = newNow.startOf("day").toDate();
    var end = newNow.endOf("day").toDate();
    this.setState({ start, end });
  }
  state = {
    flag: false,
    value: "",
    deliveryBoy: "",
    searchPhrase: "",
    deliveryBoys: [],
    start: "",
    end: "",
  };

  inputStyle = { background: "#fff" };

  buttonStyles = {
    height: "100%",
    background: "#0f9d58",
    borderRadius: "0px",
    color: "#fff",
    padding: "5px",
  };

  render() {
    return (
      <div>
        <Container>
          <div className="delivery-boi__header">
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={(e) =>
                console.log("something", this.props.history.push("newUser"))
              }
            >
              Add New Users
            </div>

            <h1 style={{ color: "#63b9d9", fontWeight: "500" }}>
              Delivery Boys
            </h1>

            <div className="delivery-boi__seach-bar">
              <input
                className="delivery-boi__seach"
                type="text"
                value={this.state.searchPhrase}
                onChange={(e) => {
                  if (e.target.value.trim() !== "") {
                    let filtered = this.state.deliveryBoys.filter(
                      (deliveryBoy) =>
                        deliveryBoy.name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                    );

                    this.setState({
                      deliveryBoys: filtered,
                      searchPhrase: e.targetValue,
                    });
                  } else {
                    this.setState({
                      deliveryBoys: this.props.deliveryBoys,
                      searchPhrase: e.targetValue,
                    });
                  }
                }}
                placeholder="Search delivery boy Name"
              />
              <Button variant="contained" style={this.buttonStyles}>
                <SearchIcon />
              </Button>
            </div>
          </div>
          {this.state.deliveryBoys?.map((deliveryBoy, index) => (
            <Card
              style={{
                width: "100%",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                marginTop: "10px",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={avatar}
                    alt="avatar"
                    className="delivery-boi__avatar"
                  />
                  {this.state.flag && (
                    <Radio
                      checked={this.state.value === index}
                      onChange={(e) => this.handleChange(index, deliveryBoy)}
                      //value="a"
                      color="default"
                      // name="radio-button-demo"
                      inputProps={{ "aria-label": "D" }}
                    />
                  )}
                </div>
                <div className="delivery-boi__info">
                  <h3>
                    {deliveryBoy?.name} ({deliveryBoy?.number})
                  </h3>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <PhoneIcon style={{ marginRight: "12px" }} />{" "}
                    <h3>{deliveryBoy?.mobileNumber}</h3>
                  </div>
                </div>

                <div className="delivery-boi__actions">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => this.remove(deliveryBoy)}
                  >
                    Remove
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => this.editDeliveryBoy(deliveryBoy)}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => this.editPassword(deliveryBoy)}
                  >
                    Change Password
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </Container>
        {this.state.flag && (
          <div>
            <Button
              style={{
                width: "100%",
                bottom: 0,
                left: 0,
                backgroundColor: "#39b97b",
                color: "white",
                fontSize: 20,
                display: "flex",
                marginTop: "10px",
                position: "fixed",
              }}
              onClick={(e) => this.allocate()}
              variant="contained"
            >
              ALLOCATE
            </Button>
          </div>
        )}
      </div>
    );
  }
  getNumber = async (id) => {
    let outFor = await this.props.getPending({
      start: this.state.start,
      end: this.state.end,
      status: "OUT_FOR_DELIVERY",
      id: id,
    });
    return outFor.length;
  };
  editDeliveryBoy = (deliveryBoy) => {
    this.props.history.push({
      pathname: "/editDeliveryBoi",
      state: { detail: deliveryBoy },
    });
  };
  editPassword = (deliveryBoy) => {
    this.props.history.push({
      pathname: "/editPassword",
      state: { detail: deliveryBoy },
    });
  };
  remove = (deliveryBoy) => {
    let { partyId, _id } = deliveryBoy;
    this.props.update({ isDeleted: true, isActive: false, partyId, _id });
  };
  handleChange = (index, deliveryBoy) => {
    this.setState({ value: index, deliveryBoy: deliveryBoy._id });
  };

  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.location.state.orderIds != prevProps.location.state.orderIds
  //   ) {
  //     this.setState({ flag: true });
  //   }
  // }
  allocate = () => {
    let type = this.props.location.state.type;
    let deliveryBoyId = this.state.deliveryBoy;
    let subOrderIds = this.props.location.state.subOrderIds;
    let allocatedDate = new Date();
    this.props.setDeliveryBoy({
      subOrderIds,
      deliveryBoyId,
      type,
      allocatedDate,
    });
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.deliveryBoys !== this.props.deliveryBoys) {
      let dataF = this.props.deliveryBoys;
      dataF = await dataF.map(async (d) => {
        var orders = await this.getNumber(d._id);
        return { ...d, number: orders };
      });

      Promise.all(dataF).then((values) => {
        this.setState({ deliveryBoys: values });
      });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    deliveryBoys: state.auth.deliveryBoys,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    get: () => dispatch(getDeliveryBoys()),
    update: (data) => dispatch(updateDeliveryBoy(data, router)),
    setDeliveryBoy: (data) => dispatch(setDeliveryBoy(data, router)),
    getPending: (data) => dispatch(getPending2(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryBoi)
);
