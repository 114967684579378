import { postRequest, putRequest, getRequest } from "../services";
import { redirect, Notify } from "../../constants/utils";
import queryString from "query-string";
import moment from "moment";
import axios from "axios";

export const loginNow = (data, router) => async (dispatch) => {
  dispatch({ type: "SUBMITTED", payload: true });
  postRequest("auth/loginShipper", data)
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      if (response.data?.partyId) {
        localStorage.setItem("partyId", response.data.partyId);
      }
      if (response.data?.deliveryBoyId) {
        localStorage.setItem("deliveryBoyId", response.data.deliveryBoyId);
      }
      let userData = JSON.stringify(response.data.userData);
      localStorage.setItem("userData", userData);
      dispatch(storeUser(response?.data, router));
    })
    .catch((reason) => {
      dispatch({ type: "PASSWORD", payload: true });
      setTimeout(function () {
        dispatch({ type: "PASSWORD", payload: false });
      }, 3000);
    });
};

export const setLogin = (data, router) => async (dispatch) => {
  dispatch({ type: "SUBMITTED", payload: true });
  getRequest("users/get/" + data)
    .then((response) => {
      const token = localStorage.getItem("token");
      const partyId = localStorage.getItem("partyId");
      const deliveryBoyId = localStorage.getItem("deliveryBoyId");

      dispatch(
        storeUser(
          { ...response?.data, token, partyId, deliveryBoyId },
          router,
          false
        )
      );
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

export const storeUser = (data, router, navigate) => (dispatch) => {
  dispatch({ type: "STORE_USER", payload: data });

  if (navigate) {
    let route = "/";
    var prevRoute = queryString.parse(router?.location.search)?.redirect;
    route = prevRoute ? prevRoute + router?.location?.hash : route;
    redirect(router, route);
  }
};

export const logout = (router) => (dispatch) => {
  dispatch({ type: "LOGOUT" });
  router.push("/");
};

export const updateProfile = (data, router) => async (dispatch) => {
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  let userId = userData.userId;
  putRequest("users/update/" + userId, data, true)
    .then((response) => {
      let obj = {
        email: response.data[0].email,
        mobile: response.data[0].mobile,
        countryCode: response.data[0].countryCode,
        userId: response.data[0]._id,
      };
      obj = JSON.stringify(obj);
      localStorage.setItem("userData", obj);
      redirect(router, "/Orders");
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getOrders = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  const token = localStorage.getItem("token");
  putRequest("orders/getAccepted/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "ORDERS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getOrders2 = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  const token = localStorage.getItem("token");
  return putRequest("orders/getAccepted/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const createDeliveryBoy = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  data.partyId = partyId;

  return await postRequest("deliveryBoys/create", data, true)
    .then((response) => {
      dispatch({ type: "ADD_DELIVERY_BOYS", payload: response.data });
      return response;
    })
    .catch((reason) => {
      console.log("reason", { ...reason });
      return reason;
    });
};
export const getDeliveryBoys = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  getRequest("deliveryBoys/get/" + partyId)
    .then((response) => {
      dispatch({ type: "DELIVERY_BOYS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

export const updateDeliveryBoy = (data, router) => async (dispatch) => {
  putRequest("deliveryBoys/update/" + data._id, data, true)
    .then((response) => {
      console.log(response.data);

      dispatch({ type: "DELIVERY_BOYS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

export const updateDeliveryBoyPassword = (data, router) => async (dispatch) => {
  let userData = localStorage.getItem("userData");
  let userId = userData._id;

  putRequest("deliveryBoys/updatePassword/" + data.id, data, true)
    .then((response) => {
      console.log(response.data);
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const setPassword = (data, router) => async (dispatch) => {
  postRequest("users/setPassword/", data, true)
    .then((response) => {
      redirect(router, "/login");
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

export const getAllocated = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  const token = localStorage.getItem("token");
  putRequest("orders/getAllocated/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "ORDERS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getAllocated2 = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  const token = localStorage.getItem("token");

  return putRequest("orders/getAllocated/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      console.log(reason);

      dispatch({ type: "SUBMITTED", payload: false });
    });
};
export const setParty = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  data.partyId = partyId;
  let start = data.start;
  let end = data.end;
  let offset = data.offset;
  delete data.start;
  delete data.end;
  delete data.offset;
  putRequest("cart/updateCarts/", data, true)
    .then((response) => {
      dispatch(getAllocated({ start, end, offset }));
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const setDeliveryBoy = (data, router) => async (dispatch) => {
  putRequest("orders/updateDeliveryBoy/", data, true)
    .then((response) => {
      redirect(router, "/Orders");
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getDelivered = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  const token = localStorage.getItem("token");
  putRequest("orders/getDelivered/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "ORDERS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getDelivered2 = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  const token = localStorage.getItem("token");
  return putRequest("orders/getDelivered/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getUserData = () => {
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  return userData;
};

export const searchDeliveryBoys = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("deliveryBoyId");
  const token = localStorage.getItem("token");

  putRequest("deliveryBoys/searchDeliveryBoys/" + partyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "DELIVERY_BOYS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getPending = (data, router) => async (dispatch) => {
  let deliveryBoyId = localStorage.getItem("deliveryBoyId");
  const token = localStorage.getItem("token");
  putRequest("orders/getPending/" + deliveryBoyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "ORDERS", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getPending2 = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  return putRequest("orders/getPending/" + data.id, data, false, {
    "access-token": token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getPending3 = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  let deliveryBoyId = localStorage.getItem("deliveryBoyId");

  return putRequest("orders/getPending/" + deliveryBoyId, data, false, {
    "access-token": token,
  })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const updateDeliveryStatus = (data, router) => async (dispatch) => {
  const token = localStorage.getItem("token");
  let newNow = moment();
  let status = data.status;
  var start = newNow.startOf("day").toDate();
  var end = newNow.endOf("day").toDate();
  putRequest("cart/updateDeliveryStatus/", data, false, {
    "access-token": token,
  })
    .then((response) => {
      if (status === "OUT_FOR_DELIVERY") {
        dispatch(getPending({ start, end }));
      }
      if (status === "ATTEMPTED") {
        redirect(router, "/DeliveryBoys");
      }
      if (status === "DELIVERED") {
        //dispatch(getPending({ start, end, status: "OUT_FOR_DELIVERY" }));
        redirect(router, "/DeliveryBoys");
      }
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const updateShipperPassword = (data, router) => async (dispatch) => {
  putRequest("parties/updateShipperPassword/" + data.id, data, true)
    .then((response) => {
      dispatch(logout());
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const outForDelivery = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");

  return putRequest("orders/outForDelivery/" + partyId, data, true)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const allocatedToDeliveryBoys = (data, router) => async (dispatch) => {
    let partyId = localStorage.getItem("partyId");

    return putRequest("orders/allocatedToDeliveryBoys/" + partyId, data, true)
        .then((response) => {
            return response.data;
        })
        .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const rejectOrder = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");
  data.partyId = partyId;
  let start = data.start;
  let end = data.end;
  let offset = data.offset;
  delete data.start;
  delete data.end;
  delete data.offset;
  putRequest("orders/rejectOrder/", data, true)
    .then((response) => {
      dispatch(getAllocated({ start, end, offset }));
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

export const getCompleted = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");

  putRequest("orders/getCompleted/" + partyId, data, false)
    .then((response) => {
      console.log("completed ordrs", response.data[0]);
      dispatch({ type: "COMPLETED", payload: response.data });
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getImage = (data, router) => async (dispatch) => {
  return putRequest("orders/getImage/", data, false)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};
export const getReport = (data, router) => async (dispatch) => {
  let partyId = localStorage.getItem("partyId");

  return putRequest("orders/getReport/" + partyId, data, false)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => dispatch({ type: "SUBMITTED", payload: false }));
};

export const getOrder = (id) => async (dispatch) => {
  const response = await getRequest("cart/getSubOrders/" + id);
  // let data = response.data;
  // let dataArray = [];
  // dataArray.push(data);
  // dispatch({ type: "ORDERS", payload: dataArray });
  return response;
};
