import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import "./App.css";
import Login from "./components/Login";
import NavBar from "./components/common/NavBar/index";
import Orders from "./components/Orders";
import Drawer from "./components/Drawer";
import Profile from "./components/Profile";
import "./App.css";
import ListOrders from "./components/Orders/listOrders";
import DeliveryBoi from "./components/DeliveryBoi";
import { setLogin } from "./store/actions/authActions";
import editDeliveryBoi from "./components/DeliveryBoi/editDeliveryBoi";
import newUser from "./components/DeliveryBoi/newUser";
import editPassword from "./components/DeliveryBoi/editPassword";
import password from "./components/Login/setPassword";
import orderDetails from "./components/Orders/orderDetails";
import DeliveryBoys from "./components/DeliveryBoys";
import ShowPendingOrders from "./components/DeliveryBoys/showPendingOrders";
import editShipperPassword from "./components/Profile/editShipperPassword";
import FulFilledReports from "./components/Fulfilled_Report";
import Attempted from "./components/DeliveryBoys/attempted";
import Delivered from "./components/DeliveryBoys/delivered";
import Driver from "./components/DeliveryBoi/AllocatedToDriver";


class App extends React.Component {
  componentDidMount() {
    const token = localStorage.getItem("token");

    if (token) {
      const data = jwtDecode(token);
      this.props.setLogin(data.id);
    }
  }

  render() {
    const { deliveryBoyId } = this.props;
    const token = localStorage.getItem("token");

    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          token !== null ? (
            <Drawer>
              <Component {...props} />
            </Drawer>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
    const NonDrawer = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          token !== null ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );

    const AuthRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            token !== null ? (
              deliveryBoyId ? (
                <Redirect to="/DeliveryBoys" />
              ) : (
                <Redirect to="/Orders" />
              )
            ) : (
              <Component {...props} />
            )
          }
        />
      );
    };

    return (
      <BrowserRouter>
        {/* <PrivateRoute component={NavBar} /> */}
        <Switch>
          {/* <Nav /> */}
          {/* <Drawer> */}
          <PrivateRoute path="/" component={Orders} exact />
          {/* </Drawer> */}
          {/* <PrivateRoute
            path="/other"
            exact
            component={() => <h1>Fulfilled Reports</h1>}
          /> */}
          <PrivateRoute path="/profile" component={Profile} exact />
          <PrivateRoute path="/editDeliveryBoi" component={editDeliveryBoi} />
          <PrivateRoute path="/newUser" component={newUser} />
          <PrivateRoute path="/ListOrders" exact component={ListOrders} />
          <PrivateRoute path="/editPassword" component={editPassword} />
          <PrivateRoute path="/Orders" exact component={Orders} />
          <PrivateRoute path="/delivery-boys" exact component={DeliveryBoi} />
          <PrivateRoute path="/drivers" exact component={Driver} />

          <Route path="/setPassword" exact component={password} />
          <PrivateRoute path="/DeliveryBoys" exact component={DeliveryBoys} />
          <PrivateRoute path="/orderDetails" component={orderDetails} />
          <PrivateRoute path="/delivered" component={Delivered} />
          <PrivateRoute
            path="/editShipperPassword"
            component={editShipperPassword}
          />
          <PrivateRoute path="/attempted" component={Attempted} />
          <PrivateRoute
            path="/ShowPendingOrders"
            component={ShowPendingOrders}
          />
          <NonDrawer path="/fulFilledReports" component={FulFilledReports} />
          <AuthRoute path="/login" component={Login} exact />
          {/* <Route component={ComponentNotFound} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    deliveryBoyId: state.auth.deliveryBoyId,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    setLogin: (data) => dispatch(setLogin(data, router)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
