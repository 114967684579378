import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { connect } from "react-redux";
import { redirect } from "../../constants/utils";
import { TextField, Radio, withStyles, Button } from "@material-ui/core";
import { updateDeliveryStatus } from "../../store/actions/authActions";
//import { black, skyblue } from "@material-ui/core/colors";

const BlueRadio = withStyles({
  root: {
    color: "#555555",
    "&$checked": {
      color: "skyblue",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
class Attempted extends Component {
  state = {
    value: "",
    remarks: "",
  };
  render() {
    let value = this.state.value;
    return (
      <Container>
        <div
          onClick={(e) => this.setState({ value: "DOOR_LOCKED" })}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #999",
          }}
        >
          {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
          <h3
            style={{
              color: value === "DOOR_LOCKED" ? "skyblue" : "#555555",
              fontSize: 20,
            }}
          >
            Door Locked
          </h3>
          <BlueRadio
            checked={value === "DOOR_LOCKED"}
            onChange={(e) => this.setState({ value: e.target.value })}
            value="DOOR_LOCKED"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        <div
          onClick={(e) => this.setState({ value: "RECIPIENT_NOT_AVAILABLE" })}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #999",
            marginTop: "10px",
          }}
        >
          {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
          <h3
            style={{
              color:
                value === "RECIPIENT_NOT_AVAILABLE" ? "skyblue" : "#555555",
              fontSize: 20,
            }}
          >
            Recipient Not Available
          </h3>
          <BlueRadio
            checked={value === "RECIPIENT_NOT_AVAILABLE"}
            onChange={(e) => this.setState({ value: e.target.value })}
            value="RECIPIENT_NOT_AVAILABLE"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        <div
          onClick={(e) => this.setState({ value: "WRONG_ADDRESS" })}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #999",
            marginTop: "10px",
          }}
        >
          {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
          <h3
            style={{
              color: value === "WRONG_ADDRESS" ? "skyblue" : "#555555",
              fontSize: 20,
            }}
          >
            Wrong Address
          </h3>
          <BlueRadio
            checked={value === "WRONG_ADDRESS"}
            onChange={(e) => this.setState({ value: e.target.value })}
            value="WRONG_ADDRESS"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        <div
          onClick={(e) => this.setState({ value: "NOT_ACCEPTED" })}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #999",
            marginTop: "10px",
          }}
        >
          {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            style={{ display: "flex", flex: 1 }}
          /> */}
          <h3
            style={{
              color: value === "NOT_ACCEPTED" ? "skyblue" : "#555555",
              fontSize: 20,
            }}
          >
            Not Accepted
          </h3>
          <BlueRadio
            checked={value === "NOT_ACCEPTED"}
            onChange={(e) => this.setState({ value: e.target.value })}
            value="NOT_ACCEPTED"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        <div
          onClick={(e) => this.setState({ value: "OTHER" })}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #999",
            marginTop: "10px",
          }}
        >
          <h3
            style={{
              color: value === "OTHER" ? "skyblue" : "#555555",
              fontSize: 20,
            }}
          >
            Other
          </h3>
          <BlueRadio
            checked={value === "OTHER"}
            onChange={(e) => this.setState({ value: e.target.value })}
            value="OTHER"
            name="radio-button-demo"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        <TextField
          id="outlined-basic"
          label="Remarks"
          variant="outlined"
          onChange={(e) => this.setState({ remarks: e.target.value })}
          style={{ display: "flex", flex: 1, marginTop: "20px" }}
        />
        <div
          onClick={(e) =>
            this.props.updateDeliveryStatus({
              status: "ATTEMPTED",
              remarks: this.state.remarks,
              reason: this.state.value,
              subOrderId: this.props.location.state.subOrderId,
            })
          }
          style={{
            cursor: "pointer",
            backgroundColor: "#21ad69",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <p style={{ color: "white", fontSize: 30, display: "flex" }}>
            SUBMIT
          </p>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    updateDeliveryStatus: (data) =>
      dispatch(updateDeliveryStatus(data, router)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attempted)
);
