import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import flowers from "../../assets/images/flowers.jpg";
import Row from "./row";

class cartComponent extends Component {
  render() {
    console.log("this is state of cart", this.props.cart);
    return (
      <div>
        <div
          style={{
            backgroundColor: "#fbedc2",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Special Instructions
        </div>
        <div
          style={{
            backgroundColor: "#fbedc2",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          Not Available
        </div>
        <img
          style={{ marginTop: "20px" }}
          src={this.props.cart?.products[0]?.images?.[0]}
          alt=""
          height="350px"
          width="350px"
        />
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: 25, color: "skyblue" }}>
              {this.props.cart?.products[0]?.internalName}
            </p>
            <p style={{ fontSize: 30, color: "skyblue" }}>
              AED {this.props.cart?.amount}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "20px",
                alignItems: "center",
                fontSize: "25px",
              }}
            >
              <p>QTY</p>
              <p
                style={{
                  display: "flex",
                  height: "30px",
                  width: "30px",
                  borderRadius: "15px",
                  backgroundColor: "red",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.props.cart?.quantity}
              </p>
            </div>
          </div>
          <div>
            <p style={{ fontSize: 15, marginTop: "10px" }}>
              {this.props.cart?.products[0]?.description}
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Message on Card
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Message on Cake
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          {this.props.cart?.cakeMessage}
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Addons
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          {this.addons(this.props.cart?.addons)}
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Personalized Image
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          {this.props.cart?.image && (
            <img
              src={this.props.cart?.image ? this.props.cart?.image : ""}
              alt=""
              height="200px"
              width="200px"
            />
          )}
        </div>
        <div
          style={{
            backgroundColor: "#7abbe1",
            color: "white",
            fontSize: 20,
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          Personalized Text
        </div>
        <div
          style={{
            backgroundColor: "white",
            fontSize: 15,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        ></div>
      </div>
    );
  }

  addons = (addons) => {
    return (
      addons &&
      addons.map((addon) => (
        <Row
          image={addon?.products[0]?.images?.[0]}
          name={addon?.products[0]?.title}
          id={addon?.products[0]?.productId}
          description={addon?.products[0]?.description}
          amount={addon?.amount * (addon?.quantity ? addon.quantity : 1)}
          quantity={addon?.quantity ? addon.quantity : 1}
        />
      ))
    );
  };
}
export default withRouter(cartComponent);
