import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container, Button, TextField, Grid } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import "./style.scss";
import { updateProfile, getUserData } from "../../store/actions/authActions";

class Profile extends Component {
  state = {
    editMode: true,
    email: "",
    mobile: "",
    countryCode: "",
    name: "",
  };

  blueClrStyle = {
    color: "#7ac4df",
  };

  componentDidMount() {
    let userData = getUserData();
    this.setState({
      email: userData.email,
      mobile: userData.mobile,
      countryCode: userData.countryCode,
      name: userData.name,
    });
  }

  onModeChange = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  };

  onProfileUpdate = async () => {
    await this.props.updateProfile({
      email: this.state.email,
      mobile: this.state.mobile,
      countryCode: this.state.countryCode,
    });

    let userData = getUserData();
    this.setState({
      email: userData.email,
      mobile: userData.mobile,
      countryCode: userData.countryCode,
      name: userData.name,
    });
    this.setState({
      editMode: false,
    });
  };

  render() {
    if (!this.state.editMode) {
      return (
        <Fragment>
          <h1
            style={{ color: "#63b9d9", textAlign: "center", fontWeight: "500" }}
          >
            My Profiles
          </h1>

          <Container maxWidth="md">
            <div className="profile-container">
              <h3>{this.state.name}</h3>
              <p>
                Mobile No:{" "}
                <span style={this.blueClrStyle}>{this.state.mobile}</span>
              </p>
              <p>
                Mail ID:{" "}
                <span style={this.blueClrStyle}>{this.state.email}</span>
              </p>

              <div className="icon-box">
                <CreateIcon
                  style={{ fontSize: "18px" }}
                  onClick={this.onModeChange}
                />
              </div>
            </div>
          </Container>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <h1
            style={{ color: "#63b9d9", textAlign: "center", fontWeight: "500" }}
          >
            Update Profile Details
          </h1>

          <Container maxWidth="md">
            <div className="marb-1">
              <TextField
                type="text"
                // label="Name"
                placeholder="User Name"
                name="name"
                style={{ width: "100%" }}
                value={this.props.user.name}
                disabled
                // onChange={e => this.setState({ username: e.target.value })}
              />
            </div>

            <div className="marb-1">
              <TextField
                type="text"
                // label="Name"
                placeholder="Email"
                name="name"
                style={{ width: "100%" }}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>

            <div className="marb-1">
              <Grid container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    placeholder="Enter your area code"
                    variant="standard"
                    type="number"
                    name="phone"
                    style={{ width: "100%" }}
                    value={this.state.countryCode}
                    onChange={(e) =>
                      this.setState({ countryCode: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={8}>
                  <div>
                    <TextField
                      placeholder="Enter your mobile here"
                      variant="standard"
                      type="number"
                      name="phone"
                      style={{ width: "100%" }}
                      value={this.state.mobile}
                      onChange={(e) =>
                        this.setState({ mobile: e.target.value })
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <Button
              variant="outlined"
              fullWidth={true}
              onClick={this.onModeChange}
              style={{ borderRadius: "0" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              fullWidth={true}
              style={{
                background: "#f37f0a",
                color: "#fff",
                borderRadius: "0",
                marginTop: "10px",
              }}
              onClick={this.onProfileUpdate}
            >
              Save
            </Button>
          </Container>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    updateProfile: (data) => dispatch(updateProfile(data, router)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
