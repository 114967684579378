import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import img from "../../assets/images/excel.jpg";
import moment from "moment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { redirect } from "../../constants/utils";
import { connect } from "react-redux";
import {
  getPending,
  updateDeliveryStatus,
} from "../../store/actions/authActions";
import Row from "../Orders/row";

class showPendingOrders extends Component {
  state = {
    timeSlots: [],
    showOrders: false,
    selectedTimeSlot: "",
  };
  componentDidMount = async () => {
    //get Pending orders
    let newNow = moment();
    var start = newNow.startOf("day").toDate();
    var end = newNow.endOf("day").toDate();
    let flag = true;
    if (this.props.location.state.carts) {
      flag = false;
      await this.props.setCarts(this.props.location.state.carts);
    }
    if (this.props.location.state.type === "pending" && flag) {
      await this.props.getPending({ start, end });
    }
    if (this.props.location.state.type === "out" && flag) {
      await this.props.getPending({ start, end, status: "OUT_FOR_DELIVERY" });
    }
    if (this.props.location.state.type === "attempted" && flag) {
      await this.props.getPending({ start, end, status: "ATTEMPTED" });
    }
    if (this.props.location.state.type === "total" && flag) {
      this.setState({ showOrders: true });
      let newStart = newNow.startOf("month").toDate();
      let newEnd = newNow.endOf("month").toDate();
      if (this.props.location.state.from) {
      } else {
        await this.props.getPending({
          start: newStart,
          end: newEnd,
          status: "DELIVERED",
        });
      }
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.orders !== this.props.orders) {
      let orders = JSON.parse(JSON.stringify(this.props.orders));
      let timeSlots = [];
      orders.map((order) => {
        let timeSlot = timeSlots.filter(
          (timeS) => timeS === order[0].shippingTime
        );
        if (timeSlot.length > 0) {
        } else {
          timeSlots.push(order[0].shippingTime);
        }
      });
      timeSlots = timeSlots.filter((v, i, a) => a.indexOf(v) === i);
      this.setState({ timeSlots: timeSlots });
    }
  };

  onOutForDelivery = (subOrderId, type) => {
    // let cartIds = [];
    // const cartNodes = document.querySelectorAll(`.cart-sel-${odrId}`);
    //
    // cartNodes.forEach((node) => {
    //   cartIds.push(parseInt(node.dataset.id));
    // });
    if (this.props.location.state.type === "pending") {
      this.props.updateDeliveryStatus({
        status: "OUT_FOR_DELIVERY",
        subOrderId: subOrderId,
      });
    }
    if (
      this.props.location.state.type === "out" ||
      this.props.location.state.type === "attempted"
    ) {
      if (type === "attempted") {
        this.props.history.push({
          pathname: "/attempted",
          state: {
            subOrderId: subOrderId,
          },
        });
      }
      if (type === "delivered") {
        this.props.history.push({
          pathname: "/delivered",
          state: {
            subOrderId: subOrderId,
          },
        });
      }
    }
  };

  render() {
    let type = this.props.location.state.type;
    let showString = "";
    if (type === "out") {
      showString = "Out For Delivery";
    } else if (type === "attempted") {
      showString = "Attempted Orders";
    } else if (type === "pending") {
      showString = "Pending Orders";
    } else if (type === "total") {
      showString = "Delivered This Month";
    }
    return (
      <Container>
        <div className="delivery-boi__header">
          <div></div>
          <h1 style={{ color: "#63b9d9", fontWeight: "500" }}>{showString}</h1>
          <div></div>
        </div>
        {!this.state.showOrders && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
              flexDirection: "column",
            }}
          >
            {this.state.timeSlots.map((timeSlot) => (
              <div
                onClick={(e) => {
                  this.setState({
                    selectedTimeSlot: timeSlot,
                    showOrders: true,
                  });
                }}
                style={{
                  width: "70%",
                  height: "40px",
                  borderRadius: "4px",
                  border: "2px solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                <h3>{timeSlot}</h3>
              </div>
            ))}
          </div>
        )}
        {this.state.showOrders &&
          this.props.location.state.type !== "total" &&
          this.renderOrders(this.props.orders, this.state.selectedTimeSlot)}
        {this.state.showOrders &&
          this.props.location.state.type === "total" &&
          this.state.timeSlots.map((slot) =>
            this.renderOrders(this.props.orders, slot)
          )}
      </Container>
    );
  }

  renderOrders = (orders, selectedTimeSlot) => {
    // let orders = this.state.orders;

    let lastIndex = orders.length - 1;
    let parentArray = [];
    orders &&
      orders.map((order, index) => {
        let amount = 0;
        let shipping = 0;
        order.map((o) => {
          amount = amount + o.amount * o.quantity;
          if (shipping < o.shippingPrice) {
            shipping = o.shippingPrice;
          }
          if (o?.addons?.length > 0) {
            o.addons.map((addon) => {
              amount = amount + addon.amount * addon.quantity;
            });
          }
        });
        amount = amount + shipping;
        order[0].shippingTime === selectedTimeSlot &&
          parentArray.push(
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: index == lastIndex ? "110px" : "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  width: "100%",

                  flexDirection: "column",
                  boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                  borderColor: "#d9d7d7",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#7abbe1",
                    display: "flex",
                    height: "60px",
                    justifyContent: "space-between",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* {!(this.props.location.state.type == "Delivered") && (
                        <WhiteBackgroundCheckbox
                          checked={this.state.selectedIds.indexOf(id) !== -1}
                          onChange={(e) => this.checkboxPressed(id, index)}
                          name="checkedB"
                          color="primary"
                        />
                      )} */}
                    <p
                      style={{ color: "white", cursor: "pointer" }}
                      // onClick={(e) => {
                      //   this.props.history.push({
                      //     pathname: "/orderDetails",
                      //     state: {
                      //       orderId: orderId,
                      //       deliveryInfo: dataChunck[0]?.deliveryAddress,
                      //       shippingInfo: {
                      //         type: dataChunck[0]?.shippingType,
                      //         date: dataChunck[0]?.shippingDate,
                      //         time: dataChunck[0]?.shippingTime,
                      //         price: dataChunck[0]?.shippingPrice,
                      //       },
                      //       carts: dataChunck,
                      //     },
                      //   });
                      // }}
                    >
                      {order[0]?.subOrderId}
                    </p>
                  </div>
                  <p style={{ color: "white" }}>
                    AED: {parseFloat(amount.toFixed(2))}
                  </p>
                </div>
                <div
                  style={{
                    borderBottom: "2px solid",
                    borderColor: "#d9d7d7",
                  }}
                >
                  <div style={{ marginTop: 20, marginLeft: 10 }}>
                    <h4>{order[0]?.address?.name}</h4>
                    <p style={{ marginTop: 10, marginBottom: 20 }}>
                      {order[0]?.address?.recipientAddress
                        ? order[0].address?.recipientAddress
                        : ""}{" "}
                      {order[0]?.address?.landmark
                        ? order[0].address?.landmark
                        : ""}{" "}
                      {order[0]?.address?.recipientCity
                        ? order[0].address?.recipientCity
                        : ""}{" "}
                      {order[0]?.address?.phone ? order[0].address?.phone : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 10,
                      marginBottom: 20,
                    }}
                  >
                    <h4>{order[0]?.shippingType + ":"}</h4>
                    <p>[AED. {shipping} ]</p>
                    <p style={{ marginLeft: 10 }}>
                      |{" "}
                      {moment(order[0]?.shippingDate)
                        .utc(true)
                        .format("Y-MM-DD")}{" "}
                      |
                    </p>
                    <p style={{ marginLeft: 10 }}>{order[0]?.shippingTime} </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    marginBottom: "20px",
                  }}
                >
                  {order &&
                    order.map((cart) => (
                      <div
                        sytle={{ display: "flex", width: "100%" }}
                        data-id={cart._id}
                      >
                        <Row
                          image={cart?.products?.[0]?.images?.[0]}
                          name={cart?.products[0]?.title}
                          id={cart?.products[0]?.productId}
                          description={cart?.products[0]?.description}
                          amount={
                            cart?.amount * (cart?.quantity ? cart.quantity : 1)
                          }
                          quantity={cart?.quantity ? cart.quantity : 1}
                        />
                        {this.addons(cart.addons)}
                      </div>
                    ))}
                </div>
                {this.props.location.state.type === "pending" && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      onClick={() => this.onOutForDelivery(order[0].subOrderId)}
                      style={{
                        display: "flex",
                        width: "40%",
                        height: "40px",
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid",
                        borderRadius: "5px",
                        color: "white",
                        backgroundColor: "#39b97c",
                      }}
                    >
                      Out for delivery
                    </div>
                  </div>
                )}
                {(this.props.location.state.type === "out" ||
                  this.props.location.state.type === "attempted") && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      onClick={() =>
                        this.onOutForDelivery(order[0].subOrderId, "attempted")
                      }
                      style={{
                        display: "flex",
                        width: "40%",
                        height: "40px",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid",
                        borderRadius: "5px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "#f0516f",
                      }}
                    >
                      Attempted
                    </div>
                    <div
                      onClick={() =>
                        this.onOutForDelivery(order[0].subOrderId, "delivered")
                      }
                      style={{
                        display: "flex",
                        width: "40%",
                        height: "40px",
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "2px solid",
                        borderRadius: "5px",
                        color: "white",
                        backgroundColor: "#39b97c",
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                )}
              </div>
              {/* {this.props.location.state.type == "Delivered" && (
                  <div
                    style={{
                      backgroundColor: "#7abbe1",
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderBottomRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontSize: 15,
                        marginLeft: "5px",
                      }}
                    >
                      Delivered
                    </p>
                    <p style={{ color: "white", fontSize: 15 }}>AbuBakar</p>
                    <p
                      style={{
                        color: "white",
                        fontSize: 15,
                        marginRight: "5px",
                      }}
                    >
                      06:35 PM
                    </p>
                  </div>
                )} */}
            </div>
          );
      });
    return parentArray;
  };
  // renderOrders = (orders, selectedTimeSlot) => {
  //   // let orders = this.state.orders;
  //   let lastIndex = orders.length - 1;
  //   let list2 = [];
  //   list2.push(
  //     orders &&
  //       orders.map((order, index) => {
  //         let count = 1;
  //         //let amount = order?.amount;
  //         let id = order?._id;

  //         let list = [];
  //         let carts = JSON.parse(JSON.stringify(order.Cart));
  //         while (carts.length > 0) {
  //           let orderId = id + "" + count;
  //           count = count + 1;
  //           let order = carts[0].deliveryAddress._id;
  //           let dataChunck = carts.filter(
  //             (e) => e.deliveryAddress._id === order
  //           );
  //           dataChunck.forEach((f) =>
  //             carts.splice(
  //               carts.findIndex((e) => e._id === f._id),
  //               1
  //             )
  //           );
  //           let flag = false;
  //           let amount = 0;
  //           let shipping = 0;
  //           dataChunck.map((cart) => {
  //             if (cart.shippingTime === selectedTimeSlot) {
  //               if (shipping < cart.shippingPrice) {
  //                 shipping = cart.shippingPrice;
  //               }
  //               amount = amount + cart?.amount * cart?.quantity;
  //               cart.addons &&
  //                 cart.addons.map((addon) => {
  //                   amount =
  //                     amount +
  //                     addon.products[0]?.Price[0]?.price * addon.quantity;
  //                 });
  //               flag = true;
  //             }
  //           });
  //           amount = amount + shipping;
  //           flag &&
  //             list.push(
  //                             );
  //         }
  //         return list;
  //       })
  //   );
  //   return list2;
  // };
  addons = (addons) => {
    return (
      addons &&
      addons.map((addon) => (
        <Row
          image={addon?.products[0]?.images?.[0]}
          name={addon?.products[0]?.title}
          id={addon?.products[0]?.productId}
          description={addon?.products[0]?.description}
          amount={
            addon.products[0]?.Price[0]?.price *
            (addon?.quantity ? addon.quantity : 1)
          }
          quantity={addon?.quantity ? addon.quantity : 1}
        />
      ))
    );
  };
}
const mapStateToProps = (state) => {
  return {
    orders: state.auth.orders,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    getPending: (data) => dispatch(getPending(data, router)),
    updateDeliveryStatus: (data) =>
      dispatch(updateDeliveryStatus(data, router)),
    setCarts: (data) => dispatch({ type: "ORDERS", payload: data }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(showPendingOrders)
);
